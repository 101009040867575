import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from "./router";
import {RouterProvider} from "react-router-dom";
import {StateContextProvider} from "./contexts/StateContextProvider";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <StateContextProvider>
            <ToastContainer/>
            <RouterProvider router={router}/>
        </StateContextProvider>
    </React.StrictMode>
);
