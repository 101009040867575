import React from 'react';
import TextResponse from "./Text";

const TripleTextResponse = ({ mask, form, filled_form, currentId, handle }) => {
    return (
        <div className={'flex flex-col gap-2 border border-gray-400 rounded-lg p-4'}>
            {
                !!form[currentId]?.children[0]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={0} />
                    : ''
            }

            {
                !!form[currentId]?.children[1]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={1} />
                    : ''
            }

            {
                !!form[currentId]?.children[2]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={2} />
                    : ''
            }
        </div>
    );
};

export default TripleTextResponse;
