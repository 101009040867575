import {HiSearch} from "react-icons/hi";
import {FC} from "react";

interface Props {
    placeholder: string,
    name?: string,
    id?: string,
    onChange?: any
}

const SearchFieldComponent: FC<Props> = ({placeholder, name, id, onChange}) => {
    return (
        <div className={'flex justify-center w-full relative'}>
            <HiSearch className={'absolute text-xl text-main-color left-3 top-8 translate-y-[-50%]'}/>
            <input name={name} id={id} onChange={onChange} type="text"
                   className={'h-16 w-full rounded-xl pl-10 focus:outline-none'}
                   placeholder={placeholder}/>
        </div>
    );
};

export default SearchFieldComponent;
