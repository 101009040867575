import React, {FC, useEffect, useState} from 'react';

interface IProps {
    name: string,
    id: string,
    label: string,
    onchange: any,
    value?: any,
    classes?: any
}

const Textarea: FC<IProps> = ({name, id, label, onchange, value, classes}) => {
    const [arrayClass, setArrayClass] = useState([""]);
    useEffect(() => {
        try {
            setArrayClass(JSON.parse(classes));
        }catch (e){
            setArrayClass([""]);
        }
    }, []);

    return (
        <div className="flex flex-col items-start bg-white rounded-xl w-full px-4 py-3 focus:outline-none focus:border focus:border-gray-600">
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
            <textarea id={id} name={name} value={value}
                      onChange={({target}) => onchange(target.value)}
                      className={"block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:outline-none focus:border focus:border-gray-600  "+ arrayClass?.join(" ")}
                      placeholder="Escreva aqui..."></textarea>

        </div>
    );
};

export default Textarea;
