import {FC, useEffect, useState} from 'react';
import { toast } from 'react-toastify';

interface Props {
    title: string,
    status?: string | null,
    info?: string[],
    click?: any,
    checkbox?: boolean,
    checkboxAction?: any,
    checkboxId?: any,
    checkboxValue?: any
}

const CardComponent: FC<Props> = ({title, status, info, click, checkbox, checkboxAction, checkboxId, checkboxValue}) => {
    const [statusColor, setStatusColor] = useState("bg-indigo-100 text-indigo-800");

    useEffect(() => {
        if(status){
            const st = status?.toLocaleLowerCase();
            if (['recebido', 'rascunho'].includes(st)) {
                setStatusColor('bg-sky-100 text-sky-800');
            } else if (['em andamento'].includes(st)) {
                setStatusColor('bg-amber-100 text-amber-800');
            } else if (['rejeitado'].includes(st)) {
                setStatusColor('bg-red-100 text-red-800');
            } else if (['aprovado'].includes(st)) {
                setStatusColor('bg-emerald-100 text-emerald-800');
            }
        }
    }, [status]);

    const filter = (e: any) => {
        if(
            e.target.tagName === 'P' ||
            e.target.tagName === 'SPAN' ||
            e.target.tagName === 'INPUT'
        ) return;
        const selection = window.getSelection();
        if(selection.type === 'Range') {
            toast.warning('Por favor, desmarque a seleção para clicar no card');
            return;
        }
        return click(e);
    }

    return (
        <div className='flex relative'>
            <div
                className={'flex flex-1 flex-col gap-1 justify-center bg-white rounded-xl p-4 cursor-pointer hover:bg-white/75'} onClick={filter}>
                <div className={'flex pt-1 gap-2 justify-between items-start'}>
                    <span className={'text-main-color text uppercase font-semibold text-sm pr-4'}>{title}</span>
                    {status? (
                        <span
                            className={`flex items-center ${statusColor} text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full`}>{status}</span>
                    ) : ""}
                </div>
                <div className={'flex flex-col text-xs justify-center items-start'}>
                    {info && info.map((i, index) => <p className='pr-4' key={index}>{i}</p>)}
                </div>
            </div>
            {checkbox && (
                <input 
                    className='absolute right-3 top-1/2 -translate-y-1/2 size-4' 
                    type="checkbox" 
                    data-index={checkboxId}
                    id={title.replace(" ", "_")} 
                    defaultChecked={!!checkboxValue}
                    onClick={checkboxAction} 
                />
            )}
        </div>
    );
};

export default CardComponent;
