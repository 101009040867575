import {Navigate, Outlet} from "react-router-dom";
import {HiBeaker} from "react-icons/hi";
import Waves from "../../components/Waves";
import {useStateContext} from "../../contexts/StateContextProvider";
const AuthLayout = () => {
    const {currentUser, userToken} = useStateContext();
    if(userToken){
        return <Navigate to={'/'}/>
    }
    return (
        <div>
            <section className={'h-screen overflow-hidden'}>
                <header className={'h-1/3'}><Waves /></header>
                <main className={'flex flex-col gap-6 items-center justify-center relative z-30 max-w-sm md:max-w-md left-1/2 -translate-x-1/2'}>
                    <header className={'bg-white p-5 rounded-2xl w-[168px] h-[168px] bg-logo bg-center bg-no-repeat'}></header>
                    <section className={'w-full px-4'}>
                        {<Outlet/>}
                    </section>
                </main>
            </section>
        </div>
    );
};

export default AuthLayout;
