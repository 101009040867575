import React, {FC} from 'react';
import HeaderButton from "../HeaderButton";
import {HiOutlineArrowCircleLeft, HiOutlineViewList} from "react-icons/hi";
import Button from "../Forms/Button";
import {IForm} from "../../views/dashboard/empresas/Add";
import FloatingInputComponent from "../Forms/FloatingInput";
import Textarea from "../Forms/Textarea";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBoxFormsComponent from '../ScrollYBoxForms';

interface Props {
    form: IForm,
    setName: any,
    setEmail: any,
    setCnpj: any,
    setUnit: any,
    setGrauRisco: any,
    setCnae: any,
    setDescriptionCnae: any,
    setCep: any,
    setTipoLogradouro: any,
    setLogradouro: any,
    setBairro: any,
    setCidade: any,
    setEstado: any,
    setNumero: any,
    onSubmit: any,
    loading: any,
    btnText: string,
}

const FormCompany: FC<Props> = ({form, setName, setEmail, setCnpj, setUnit, setGrauRisco, setCnae, setDescriptionCnae, setCep, setTipoLogradouro,
                                setLogradouro, setBairro, setCidade, setEstado, setNumero, loading, btnText, onSubmit}) => {
    return (
        <>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'empresas/listar'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'max-w-sm sm:max-w-2xl flex items-center justify-center'}>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <>
                            <FloatingInputComponent type={'text'} label={'Razão Social*'} name={'name'} id={'name'} value={form.name} onchange={setName}/>
                            <FloatingInputComponent type={'email'} label={'E-mail*'} name={'email'} id={'email'} value={form.email} onchange={setEmail}/>
                            <div className={'flex gap-3'}>
                                <FloatingInputComponent type={'text'} label={'CNPJ*'} name={'cnpj'} id={'cnpj'} value={form.cnpj} onchange={setCnpj}/>
                                <FloatingInputComponent type={'text'} label={'Unidade'} name={'unit'} id={'unit'} value={form?.unit} onchange={setUnit}/>
                            </div>
                            <div className={'flex gap-3'}>
                                <FloatingInputComponent type={'text'} label={'Grau de Risco'} name={'grau_risco'} id={'grauRisco'} value={form.grau_risco} onchange={setGrauRisco}/>
                                <FloatingInputComponent type={'text'} label={'CNAE'} name={'cnae'} id={'cnae'} value={form?.cnae} onchange={setCnae}/>
                            </div>
                            <Textarea label={"Descrição CNAE"} name={"description_cnae"} id={"descriptionCnae"} value={form?.description_cnae} onchange={setDescriptionCnae}/>
                            <div className={'flex gap-3'}>
                                <FloatingInputComponent type={'text'} label={'CEP*'} name={'cep'} id={'cep'} value={form?.address?.cep} onchange={setCep}/>
                            </div>
                            <FloatingInputComponent type={'text'} label={'Logradouro*'} name={'logradouro'} id={'logradouro'} value={form?.address?.logradouro} onchange={setLogradouro}/>
                            <FloatingInputComponent type={'text'} label={'Bairro*'} name={'bairro'} id={'bairro'} value={form?.address?.bairro} onchange={setBairro}/>
                            <FloatingInputComponent type={'text'} label={'Cidade*'} name={'cidade'} id={'cidade'} value={form?.address?.cidade} onchange={setCidade}/>
                            <div className={'flex gap-3'}>
                                <FloatingInputComponent type={'text'} label={'Estado*'} name={'estado'} id={'estado'} value={form?.address?.estado} onchange={setEstado}/>
                                <FloatingInputComponent type={'text'} label={'Numero*'} name={'numero'} id={'numero'} value={form?.address?.numero} onchange={setNumero}/>
                            </div>
                            <Button loading={loading}>{btnText}</Button>
                        </>
                    </form>

                </main>
            </ScrollYBoxFormsComponent>
        </>
    );
};

export default FormCompany;
