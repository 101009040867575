import React, {FC, SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react';
import {HiPencil, HiPlus} from "react-icons/hi";
import Modal from "../Modal";
import FileInput from "../Forms/FileInput";
import Button from "../Forms/Button";
import {IOptions} from "../../views/dashboard/configuracao/certs/Add";
import axiosClient from "../../services/axios";
import FloatingInputComponent from "../Forms/FloatingInput";
import {toast} from "react-toastify";
import Select from "../../pkg/react-tailwindcss-select";

interface IProps {
    options?: IOptions[] | null,
    select: IOptions,
    setSelect: any,
    setImported: any
}

const url = process.env.REACT_APP_BASE_URL;
const CertTemplatesComponent: FC<IProps> = ({options, select, setSelect, setImported}) => {
    const [stream, setStream] = useState<File | undefined | null>(null);
    const [title, setTitle] = useState("");
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImported(false);
    }, [show]);


    const onsubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        if (
            stream &&
            title
        ) {
            const form = new FormData();
            form.append("file", stream, 'file.pdf');
            form.append("title", title);
            axiosClient.post('certificado/importar-template', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(({status, data: {data}}) => {
                if (status >= 200 && status < 300) {
                    setShow(false);
                    setImported(true);
                    setStream(null);
                    window.open(`${url}/api/certificado/configurar-coordenadas/${data.id}`, '_blank', 'toolbar=0,location=0,menubar=0');
                    return;
                }
                toast.error("Preencha os campos abaixo!")
            }).finally(() => setLoading(false))
        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }
    }

    const editTemplate = (id:any) => {
        window.open(`${url}/api/certificado/configurar-coordenadas/${id}`, '_blank', 'toolbar=0,location=0,menubar=0');
        return;
    }

    return (
        <>
            <fieldset className={'border border-gray-500 w-full rounded-xl shadow-sm p-3 mb-4 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}>
                <legend className={'text-gray-500'}>Template</legend>
                <div className={'flex justify-between text-start gap-3'}>
                    <Select primaryColor={"red"}
                            placeholder={"Selecione"}
                            noOptionsMessage={"Nenhum resultado encontrado."}
                            isSearchable
                            value={select}
                            onChange={setSelect}
                            loading={!options}
                            options={options ? options : []}/>
                    <div className={'flex flex-col h-16 gap-2 justify-center items-stretch'}>
                        <button type={'button'} onClick={() => setShow(!show)}
                                className={'flex justify-between items-center h-full px-2 py-1 text-gray-500 rounded shadow-sm transition-all duration-300 focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}><HiPlus/>
                        </button>
                        {
                            select && (
                                <button type={'button'} onClick={() => editTemplate(select?.value) }
                                        className={'flex justify-between items-center h-full px-2 py-1 text-gray-500 rounded shadow-sm transition-all duration-300 ' +
                                            'focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}>
                                    <HiPencil/>
                                </button>
                            )
                        }
                    </div>
                </div>
            </fieldset>
            <Modal title={"Importar Template"} show={show} setShow={setShow} top={'top-1/3'}>
                <form action={"#"} onSubmit={onsubmit} className={'flex flex-col gap-4'}>
                    <FloatingInputComponent type={'text'} label={'Titulo Template'} name={'template'} id={'title'} onchange={setTitle}/>
                    <FileInput stream={stream} setStream={setStream} title={"Template"} accept={".pdf"} opt={"PDF"}/>
                    <Button loading={loading}>Registrar</Button>
                </form>
            </Modal>
        </>
    );
};

export default CertTemplatesComponent;
