import React, { FC, useEffect, useState } from 'react';
import MaskInput from "../../Forms/MaskInput";
import Textarea from "../../Forms/v2/Textarea";

interface IProps {
    mask: any, currentId: any, form: any, filled_form: any, handleSetFilledForm: any, index?: number
}

const TextResponse: FC<IProps> = ({ mask, currentId, form, filled_form, handleSetFilledForm, index }) => {
    const [texts, setTexts] = useState(['']);
    useEffect(() => {
        setTexts(['three_text', 'presente_ausente_na_double_text', 'select_double_text']);
    }, []);

    return (
        <>
            {
                mask !== '' ?
                    <MaskInput
                        mask={mask}
                        label={
                            ['text_file'].includes(form[currentId]?.type) ?
                                form[currentId]?.children[1]?.label :
                                texts.includes(form[currentId]?.type) ?
                                    form[currentId]?.children[index]?.label :
                                    form[currentId]?.label
                        }
                        name={['text_file'].includes(form[currentId]?.type) ?
                            form[currentId]?.children[1]?.name :
                            texts.includes(form[currentId]?.type) ?
                                form[currentId]?.children[index]?.name :
                                form[currentId]?.name}
                        id={['text_file'].includes(form[currentId]?.type) ?
                            form[currentId]?.children[1]?.id :
                            texts.includes(form[currentId]?.type) ?
                                form[currentId]?.children[index]?.id :
                                form[currentId]?.id}
                        value={
                            ['text_file'].includes(form[currentId]?.type) ?
                                filled_form[String(form[currentId].children[1].field)] || String(form[currentId].children[1].value) :
                                texts.includes(form[currentId]?.type) ?
                                    filled_form[String(form[currentId].children[index].field)] || String(form[currentId].children[index].value) :
                                    filled_form[String(form[currentId].field)] || String(form[currentId].value)
                        }
                        onchange={(value: any) =>
                            ['text_file'].includes(form[currentId]?.type) ?
                                handleSetFilledForm(value, currentId, 1) :
                                texts.includes(form[currentId]?.type) ?
                                    handleSetFilledForm(value, currentId, index) :
                                    handleSetFilledForm(value, currentId)
                        }
                    /> :
                    <Textarea label={
                        ['text_file'].includes(form[currentId]?.type) ?
                            form[currentId]?.children[1]?.label :
                            texts.includes(form[currentId]?.type) ?
                                form[currentId]?.children[index]?.label :
                                form[currentId]?.label
                    } classes={form[currentId].classes}
                        name={['text_file'].includes(form[currentId]?.type) ?
                            form[currentId]?.children[1]?.name :
                            texts.includes(form[currentId]?.type) ?
                                form[currentId]?.children[index]?.name :
                                form[currentId]?.name}
                        id={['text_file'].includes(form[currentId]?.type) ?
                            form[currentId]?.children[1]?.id :
                            texts.includes(form[currentId]?.type) ?
                                form[currentId]?.children[index]?.id :
                                form[currentId]?.id}
                        value={
                            ['text_file'].includes(form[currentId]?.type) ?
                                filled_form[String(form[currentId].children[1].field)] || String(form[currentId].children[1].value)
                                : texts.includes(form[currentId]?.type) ?
                                    filled_form[String(form[currentId].children[index].field)] || String(form[currentId].children[index].value) :
                                    filled_form[String(form[currentId].field)] || String(form[currentId].value)
                        }
                        onchange={(value: any) =>
                            ['text_file'].includes(form[currentId]?.type) ?
                                handleSetFilledForm(value, currentId, 1) :
                                texts.includes(form[currentId]?.type) ?
                                    handleSetFilledForm(value, currentId, index) :
                                    handleSetFilledForm(value, currentId)
                        }
                    />
            }
        </>
    );
};

export default TextResponse;
