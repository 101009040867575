import React from "react";
import ImageComponent from "../components/Image";
import error from "../assets/img/error.svg";
import router from "../router";


const NotFound = () => {

    return (
        <div>
            <div className={'w-full flex flex-col items-center justify-center p-4'}>
                <ImageComponent classes={'block max-w-3xl'} useFallback={true} imageUrl={error} description={"imagem de cabeçalho com objetivo puramente estético"} />
                <div className={'grid justify-items-center cursor-pointer'} onClick={() => router?.navigate(`/dashboard`)}>
                    <section className={'p-5 w-16 h-16 bg-logo bg-cover bg-no-repeat'}></section>
                    <button className={'h-10 bg-main-color px-2 py-1 text-white rounded-lg mt-4 hover:bg-main-color/90'}>
                        Retornar ao Sistema
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
