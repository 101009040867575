import React from 'react';
import Skeleton from "react-loading-skeleton";

const CardSkeletonComponent = () => {
    return (
        <div
            className={'flex flex-col gap-1 justify-center bg-white rounded-xl p-4 cursor-pointer hover:bg-white/75'}>
            <div className={'flex justify-between items-center'}>
                <p className={'text-main-color text uppercase font-semibold'}>{<Skeleton
                    width={180} height={20}/>}</p>
                <span
                    className={`flex items-center bg-gray-200 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full`}>{
                    <Skeleton width={50}/>}</span>
            </div>
            <div className={'flex flex-col text-sm justify-center items-start'}>
                <Skeleton width={150}/>
                <Skeleton width={150}/>
                <Skeleton width={150}/>
            </div>
        </div>
    );
};

export default CardSkeletonComponent;
