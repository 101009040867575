import axios from "axios";
import router from "../router";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const url = process.env.REACT_APP_BASE_URL;

const axiosClient = axios.create({
    baseURL: `${url}/api`
})

axiosClient.interceptors.request.use((config): any => {
    const token = localStorage.getItem('jwt');
    config.headers.Authorization = `Bearer ${token}`
    return config;
})

axiosClient.interceptors.response.use(response => {
    return response;
}, (error) => {
    const configResponse = error?.response
    const requestResponse = error?.request?.response

    if(!error?.response || !error?.response?.status){
        return toast.error('Erro ao tentar se conectar com o servidor');
    }
    if(error?.response?.status !== 404){
        if (configResponse) {
            toast.error(configResponse.data.message)
        } else if (requestResponse) {
            Object.values(JSON.parse(error.request.response)?.errors)
                .forEach((erro: object | any | any[]) => {
                    toast.error(erro[0])
                });
        } else if (error.message) {
            toast.error(error.message);
        }
        if (error.response.status === 401) {
            localStorage.removeItem('jwt');
            const uri = window.location.pathname.split('/').slice(-1)[0]
            if(uri === '404'){
                return
            }
            if(uri !== 'login'){
                window.location.reload();
            }
            return router?.navigate('/login');
        }
        return;
    }

    throw error;
});


export default axiosClient;
