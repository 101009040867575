import React, {FC} from 'react';
import {HiArchive, HiOutlineCloudDownload} from "react-icons/hi";

const url = process.env.REACT_APP_BASE_URL;

interface Props {
    title: any,
    click?: any
}

const CertCardComponent: FC<Props> = ({title = "", click}) => {
    return (
        <div
            className={'flex flex-col gap-1 justify-center bg-white rounded-xl w-full p-4 cursor-pointer hover:bg-white/75'} onClick={click}>
            <div className={'grid grid-cols-5 items-center gap-3 h-20 px-1 w-full'}>
                <div className={'col-span-4 w-full items-center'}>
                    <div className={'grid grid-cols-1 gap-1 items-center'}>
                        <HiArchive className={'text-2xl text-main-color'}/>
                        <p className={'line-clamp-2'}>
                            {title}
                        </p>
                    </div>
                </div>
                <div className={'col-span-1 justify-self-end'}>
                    <a target={'_blank'}
                       href={`${url}/api/certificado/download/${title}`} download>
                        <HiOutlineCloudDownload className={'text-5xl text-gray-600 hover:text-main-color'}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CertCardComponent;
