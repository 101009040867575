import React, {FC, useEffect, useState} from 'react';
import {HiOutlineCheck, HiOutlineX, HiPencil, HiPlus} from "react-icons/hi";
import Modal from "../Modal";
import Button from "../Forms/Button";
import FloatingInputComponent from "../Forms/FloatingInput";
import {toast} from "react-toastify";

interface IProps {
    values?: any,
    form: any
}
function isHigherThanZero(array:any[])
{
    return array?.length > 0;
}
const OptionFormComponent:FC<IProps> = ({values,form}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [acceptValue, setAcceptValue] = useState<string>("");
    const [acceptValues, setAcceptValues] = useState<string[]>([]);
    useEffect(() => {
        if(values){
            if(!Array.isArray(values)){
                values = JSON.parse(values);
            }
            setAcceptValues(values);
        }
    }, []);

    const addValues = () => {
        setAcceptValues((acceptValues) => [
            ...acceptValues,
            acceptValue
        ])
        setAcceptValue(() => "");
    }
    const save = () => {
        setLoading(!loading);
        if(acceptValues.length > 0){
            form((schemaForm: any) => ({...schemaForm, accept_values: acceptValues}))
            setShow(false);
        }else{
            toast.error("Preencha ao menos uma opção.")
        }
        setLoading(false);
    }

    const openModal = () => {
        setShow(true);
        setLoading(false);
    }

    function removeValue(key: number) {
        setAcceptValues((acceptValues) => [
            ...acceptValues.filter((e, index) => index !== key)
        ])
    }

    return (
        <>
            <button type={'button'} data-higherthanzero={isHigherThanZero(values)} onClick={openModal}
                    className={'w-full flex justify-center data-[higherthanzero=true]:bg-green-600 gap-3 items-center px-2 text-white h-14 rounded-lg shadow-sm bg-gray-500 hover:bg-main-color'}>
                {isHigherThanZero(values) ? <HiOutlineCheck/> : <HiPlus/>} {isHigherThanZero(values) ? `Você configurou ${values.length} itens` : `Configure as opções`}
            </button>
            <Modal title={"Adicionar Campo ao Formulário"} show={show} setShow={setShow} top={"top-1/2"}>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex justify-between items-center text-start gap-3'}>
                        <FloatingInputComponent
                            type={'text'} label={'Valor Aceito'} name={'valor'} id={'valor'} value={acceptValue} onchange={setAcceptValue}/>
                        <button type={'button'} onClick={addValues}
                                className={'flex justify-between items-center h-10 px-2 text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 ' +
                                    'focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}>
                            <HiPlus/>
                        </button>
                    </div>
                    {
                        acceptValues.length > 0 && (
                            <>
                                <p className={'text-start -mb-3'}>Valores aceitos</p>
                                <ul className={'flex flex-col gap-4 border rounded p-3'}>
                                    {acceptValues?.map((e, key) => {
                                        return (
                                            <li key={key} className={'flex justify-between'}>
                                                {e}
                                                <span className={'flex gap-2'}>
                                                    <button onClick={() => removeValue(key)} className={'h-8 px-2 text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 bg-white hover:text-white ' +
                                                        'hover:bg-red-500 hover:border-red-500 focus:ring focus:ring-blue-500/20'}><HiOutlineX/></button>
                                                    </span>
                                            </li>

                                        )
                                    })}
                                </ul>
                            </>
                        )
                    }
                    <Button func={save} type={'button'} loading={loading}>Salvar</Button>
                </div>
            </Modal>
        </>
    );
};

export default OptionFormComponent

