import React, { FC } from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import '../../assets/style/campos.css';
import HeaderButton from "../HeaderButton";
import { HiOutlineArrowCircleLeft, HiOutlineDocumentAdd } from "react-icons/hi";
import FloatingInputComponent from "../Forms/FloatingInput";
import SchemaForm from "./SchemaForm";
import Button from "../Forms/Button";
import { IForm } from "../../views/dashboard/formularios/Add";
import { IOptions } from "../../views/dashboard/configuracao/certs/Add";
import FileTemplates from "./FileTemplates";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import ScrollYBoxFormsComponent from "../ScrollYBoxForms";

interface Props {
    form: IForm,
    setForm: any,
    title: string,
    setTitle: any,
    onSubmit: any,
    loading: boolean,
    btnText: string,
    options?: IOptions[] | null,
    select: IOptions,
    setSelect: any,
    setImported: any,
    templateTitle: any,
    setTemplateTitle: any
}

const FormFormulario: FC<Props> = ({ form, setForm, title, setTitle, templateTitle, setTemplateTitle, onSubmit, loading, btnText, options, select, setSelect, setImported }) => {
    return (
        <>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4 mb-4'}>
                <HeaderButton goBack={true} title={"Voltar"}>
                    <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'} />
                </HeaderButton>
            </header>
            <>
                <main className={'max-w-sm sm:max-w-2xl flex flex-col items-center justify-center'}>
                    <FileTemplates select={select} setSelect={setSelect} options={options} setImported={setImported} title={templateTitle} setTitle={setTemplateTitle}/>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <div className={'text-start flex flex-wrap gap-6 md:gap-4'}>
                            <div className="relative w-full" >
                                        <input
                                            type={'text'}
                                            id={'formulario'}
                                            className={"block rounded-xl w-full px-4 pt-1 h-16 focus:outline-none focus:border focus:border-gray-600 peer "}
                                            onChange={({ target }: any) => setTitle(target.value)}
                                            name={'formulario'}
                                            value={title}
                                            placeholder=" "
                                        />
                                        <label htmlFor={'formulario'}
                                            className={`
                                        absolute
                                        peer-placeholder-shown:scale-100
                                        peer-placeholder-shown:-translate-y-1/2
                                        peer-placeholder-shown:top-1/2
                                        peer-placeholder-shown:font-normal
                                        peer-placeholder-shown:translate-x-3
                                        peer-placeholder-shown:text-lg
                                        peer-placeholder-shown:text-gray-500
                                        text-xs
                                        text-gray-500
                                        duration-300
                                        transform
                                        -translate-y-2
                                        top-4
                                        z-5
                                        left-3.5
                                        bg-transparent
                                        rounded-full
                                `}>Título para formulário</label>
                            </div>
                            {
                               (btnText == "Gerar Formulário" || form?.form.length > 0) && <SchemaForm form={form.form} fieldsetTitle={'Campos'} setForm={setForm} />
                            }
                        </div>
                        <Button loading={loading}>{btnText}</Button>
                    </form>

                </main>
            </>
        </>
    );
};

export default FormFormulario;
