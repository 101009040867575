import {FC, useEffect, useRef, useState} from "react";
import {MdImage} from "react-icons/md";

interface Props {
    imageUrl: string,
    useFallback?: boolean,
    description: string,
    classes: string
}

enum ImageLoadState {
    Init,
    Loading,
    Complete,
    Error
}

const ImageComponent: FC<Props> = ({imageUrl, useFallback, description, classes}) => {
    const [imageLoadState, setImageLoadState] = useState(ImageLoadState.Init);
    const img = useRef(null);
    useEffect(() => {
        setImageLoadState(imageUrl ? ImageLoadState.Loading : ImageLoadState.Error)
    }, [imageUrl]);
    if(!imageUrl){
        return null;
    }
    if(imageLoadState === ImageLoadState.Error && useFallback){
        return (<div className={'p-4 rounded-xl flex items-center justify-center'}>
            <MdImage size={"10em"} />
        </div>)
    }

    return (
        <img className={classes}
             ref={img}
             src={imageUrl}
             onLoad={() => setImageLoadState(ImageLoadState.Complete)}
             onError={() => setImageLoadState(ImageLoadState.Error)}
             alt={description}/>
    );
};

export default ImageComponent;
