import {HiOutlineArrowCircleLeft} from "react-icons/hi";
import HeaderButton from "../../../../components/HeaderButton";
import Scrollbars from "react-custom-scrollbars-2";
import FloatingInputComponent from "../../../../components/Forms/FloatingInput";
import React, {SyntheticEvent, useState} from "react";
import Button from "../../../../components/Forms/Button";
import {useStateContext} from "../../../../contexts/StateContextProvider";
import axiosClient from "../../../../services/axios";
import {toast} from "react-toastify";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBox from "../../../../components/ScrollYBox";
import ScrollYBoxFormsComponent from "../../../../components/ScrollYBoxForms";

export const AlterPasswordComponent = () => {
    const {currentUser, testToken} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if (
            password &&
            passwordConfirmation &&
            currentUser
        ) {
            axiosClient.post('senha/alterar', {
                id: currentUser?.id,
                password,
                password_confirmation: passwordConfirmation
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(({data:{message}}) => {
                    toast.success(message);
                    setPassword("");
                    setPasswordConfirmation("");
                })
                .catch(() => {
                }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            });
        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }

    }

    return (
        <div>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'/home'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'flex max-w-sm sm:max-w-2xl mt-3 items-center justify-center '}>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <FloatingInputComponent type={'password'} label={'Senha'} name={'password'} id={'password'} value={password} onchange={setPassword}/>
                        <FloatingInputComponent type={'password'} label={'Senha Confirmação'} name={'password_confirmation'} id={'passwordConfirmation'} value={passwordConfirmation} onchange={setPasswordConfirmation}/>
                        <Button loading={loading}>Alterar senha</Button>
                    </form>
                </main>
            </ScrollYBoxFormsComponent>
        </div>

    );
};
