import React, { FC } from 'react';

interface IProps {
    name: string,
    id: string,
    label: string,
    onchange: any,
    classes?: any,
    checked?: any,
    freeTarget?: boolean
}

const Checkbox: FC<IProps> = ({ name, id, label, onchange, classes, checked, freeTarget }) => {
    return (
        <div className="flex items-center bg-white rounded-xl w-full px-4 pt-1 h-16 focus:outline-none focus:border focus:border-gray-600">
            <input id={id} type="checkbox" name={name}
                onChange={({ target }: any) => onchange(!!freeTarget ? target : target.checked)}
                checked={checked !== "" ? !!checked : false}
                className={"w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" + classes?.join(" ")} />
            <label htmlFor={id} className="w-full py-4 ml-2 text-sm font-medium text-gray-900">{label}</label>
        </div>
    );
};

export default Checkbox;
