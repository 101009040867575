import React, { FC, useEffect, useRef } from 'react';
import uuid from 'react-uuid';

interface IProps {
    stream?: File | null,
    setStream: any,
    title: string,
    opt: string,
    accept: string,
    url?: string,
    id?: string | null
}

interface IRef {
    props: {
        children: any,
        stream: any
    }, ref: any
}

const FileRef = React.forwardRef<any>((props: any, ref: any) => {
    return (<label ref={ref} htmlFor={props?.id} className={`flex flex-col items-center justify-center w-1/2 md:w-1/3 h-18 border-2 px-2
                ${props.stream ? 'border-main-color border-solid' : 'border-gray-300 border-dashed'} rounded-lg cursor-pointer bg-white hover:bg-white-100 break-all`}>
        <div className="flex flex-col items-center justify-center py-3 px-2 w-full">
            <svg fill="#000000" className={`w-3 h-3 ${props.stream ? 'text-main-color' : 'text-gray-400'}`} version="1.1" viewBox="0 0 487 487">
                <g>
                    <g>
                        <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z     M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9    v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z     M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z     M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z" />
                    </g>
                </g>
            </svg>
            <p className={`text-xs ${props.stream ? 'text-main-color' : 'text-gray-500'}`}>
                <span className="font-semibold">{props.stream ? props.stream.name : props.title} </span>
            </p>
        </div>
        <input onChange={props.getFileEvent} accept={props.accept} id={props?.id} type="file" className="hidden" capture="environment" />
    </label>)
});

const FileInputComponent: FC<IProps> = ({ stream, setStream, title, opt, accept, url, id=null }) => {
    const bg = useRef<HTMLLabelElement | null>(null);
    const setBg = (url: string, element = null) => {
        if(!element) element = bg.current;
        if (element) {
            element.style.backgroundImage = `url('${url}')`
            element.style.backgroundRepeat = 'no-repeat';
            element.style.backgroundPosition = 'center';
            element.style.backgroundSize = 'contain';
        }
    }

    const unsetBg = () => {
        if (bg.current) {
            bg.current.style.backgroundImage = ``;
            bg.current.style.backgroundRepeat = '';
            bg.current.style.backgroundPosition = '';
            bg.current.style.backgroundSize = '';
        }
    }
    useEffect( () => {
        if(!id) { 
            id = String(uuid());
        }
    }, [])

    useEffect(() => {
        if(url){
            setBg(url);
        }
    }, [url]);
    
    useEffect(() => {
        if(!stream && !url){
            unsetBg();
        }
    }, [stream, url]);

    const _setStream = (file: any, element: any) => {
        setStream(file)
        if (file) {
            setBg(URL.createObjectURL(file), element);
        }
    };

    const getFileEvent = (e: any) => {
        return _setStream(e.target.files?.item(0), bg.current);
    }


    return (
        <div className="flex items-center justify-center w-full">
            <div className='bg-white w-full h-30 rounded-lg px-4 py-3 flex flex-col items-start'>
                <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Foto <span className='text-xs'>({title})</span></label>
                <FileRef {...{stream, title, getFileEvent, accept, id}} ref={bg} />
        </div>
        </div >
    );
};

export default FileInputComponent;
