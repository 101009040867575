'use client';
import React, { FC, useEffect, useState } from 'react';

interface Rows {
  key: number,
  type: string,
  model: string,
}

interface IProps {
  headers?: any,
  rows?: Rows[],
  edit?: any,
  exclude?: any
}

const TableComponent: FC<IProps> = ({ headers, rows, edit, exclude }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {
              headers?.map((item, index) => (
                <th key={`${item}.${index}.header`} scope="col" className="px-6 py-3 text-center">
                  {item}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.length > 0 && rows?.map(({ type, model, key }, index) => (
              <tr key={`${type}.${model}.${key}.row`} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <td className="px-6 py-4 text-center">
                  {String(type).substring(0, 30)}
                </td>
                <td className="px-6 py-4 text-center">
                  {String(model).substring(0, 30)}
                </td>
                <td className="px-6 py-4">
                  <div className='flex gap-3 justify-center'>
                    <button type='button' onClick={() => edit(index)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Editar</button>
                    <button type='button' onClick={() => exclude(index)} className="font-medium text-red-600 dark:text-red-500 hover:underline">Excluir</button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>

  );
}

export default TableComponent;