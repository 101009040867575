import React, {SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react';
import CertTemplates from "../../../../components/Cert/CertTemplates";
import FloatingInputComponent from "../../../../components/Forms/FloatingInput";
import FileInput from "../../../../components/Forms/FileInput";
import axiosClient from "../../../../services/axios";
import Button from "../../../../components/Forms/Button";
import {HiOutlineArrowCircleLeft} from "react-icons/hi";
import HeaderButton from "../../../../components/HeaderButton";
import {toast} from "react-toastify";
import {GenerateMask} from "../../Home";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBox from "../../../../components/ScrollYBox";
import ScrollYBoxFormsComponent from "../../../../components/ScrollYBoxForms";

const url = process.env.REACT_APP_BASE_URL;

export interface IError {
    message: string
}

export interface IOptions {
    label: string,
    value: string
}

interface ITemplateList {
    "id": string,
    "title": string,
    "template_path": string
}

const AddCertComponent = () => {
    const [stream, setStream] = useState<File | null | undefined>(null);
    const [instrutorNome, setInstrutorNome] = useState<string | null | undefined>(null);
    const [instrutorCpf, setInstrutorCpf] = useState<string | null | undefined>(null);
    const [tituloZip, setTituloZip] = useState<string | null | undefined>(null);
    const [select, setSelect] = useState<IOptions|any>(null);
    const [options, setOptions] = useState<IOptions[] | null | []>(null);
    const [loading, setLoading] = useState(false);
    const [imported, setImported] = useState(false);
    const [zip, setZip] = useState<string | null>(null);
    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        if (
            stream &&
            instrutorNome &&
            instrutorCpf &&
            tituloZip &&
            select
        ) {
            const form = new FormData();
            form.append("file", stream, 'file.xlsx');
            form.append("template", select?.value);
            form.append("instrutorNome", instrutorNome);
            form.append("instrutorCpf", instrutorCpf);
            form.append("tituloZip", tituloZip);
            axiosClient.post('certificado/gerar-certificado', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(({data: {data}}) => {
                    setZip(data.zip)
                })
                .catch(({response: {data}}) => {
                    if (data.error) {
                        toast.error(data.message);
                    } else {
                        Object.values(data?.errors).map((erro: object | any | any[]) => toast.error(erro[0]));
                    }
                }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            });

        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }

    }

    useEffect(() => {
        axiosClient.get("certificado/listar-template").then(({data: {data}}) => setOptions(data.map(({title, id}: ITemplateList) => ({"label": title.length > 40 ? title.substring(0, 40) + "..." : title, "value": id}))))
    }, [imported]);

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00")
    }, []);

    return (
        <div>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'certificado/listar'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'flex flex-col max-w-sm sm:max-w-2xl items-center justify-center'}>
                    <CertTemplates select={select} setSelect={setSelect} options={options} setImported={setImported}/>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <FloatingInputComponent type={'text'} label={'Nome do Instrutor'} name={'instrutorNome'} id={'instrutor_nome'} onchange={setInstrutorNome}/>
                        <FloatingInputComponent classes={['cpf']} type={'text'} label={'CPF do Instrutor'} name={'instrutorCpf'} id={'instrutor_cpf'} onchange={setInstrutorCpf}/>
                        <FloatingInputComponent type={'text'} label={'Título do zip'} name={'tituloZip'} id={'titulo_zip'} onchange={setTituloZip}/>
                        <FileInput stream={stream} setStream={setStream} title={"Clique ou arraste para anexar a planilha"} opt={"XLSX, CSV (MAX. 3MB)"} accept={'.xlsx, .csv'}/>
                        {zip &&
                            <a target={'_blank'}
                               className={'flex justify-center items-center h-16 text-xl w-full rounded-xl px-3 bg-zinc-500 hover:bg-zinc-500/80 text-white'}
                               href={`${url}/api/certificado/download/${zip}`} download>Download Certificado</a>
                        }
                        <Button loading={loading}>Gerar Certificados</Button>
                    </form>

                </main>
            </ScrollYBoxFormsComponent>
        </div>

    );
};

export default AddCertComponent;
