import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import {useStateContext} from "../../../contexts/StateContextProvider";


export const idToIndex = (id:string) => {
    if(id.toLowerCase().indexOf('first') !== -1) return 0
    if(id.toLowerCase().indexOf('second') !== -1) return 1
    if(id.toLowerCase().indexOf('third') !== -1) return 2
    if(id.toLowerCase().indexOf('fourth') !== -1) return 3
}

export const getChildrenValues = (e:any, schemaForm) => {
    const childId = e.getAttribute('id');
    const index = idToIndex(childId);
    const value = e.value;

    const updatedValue = {
        name: value,
        id: value?.replaceAll(/\s/g, "_").split("_").map((row, key) => {
            const array = row.split("");
            if(key > 0){
                array[0] = array[0]?.toUpperCase();
            }else{
                array[0] = array[0]?.toLowerCase();
            }
            return array.join("");
        }).join("")
    };
    const copySchema = {...schemaForm}
    if(!copySchema.children){
        copySchema.children = [];
    }
    copySchema.children[index] = {...copySchema.children[index], ...updatedValue};
    return copySchema;
}

export const getChildrenLabel = (e:any, schemaForm) => {
    const childId = e.getAttribute('id');
    const index = idToIndex(childId);
    const value = e.value;
    const copySchema = {...schemaForm}
    if(!copySchema.children){
        copySchema.children = [];
    }
    copySchema.children[index] = {...copySchema.children[index], label: value};
    return copySchema;
}

export const getChildrenValue = (e:any, schemaForm) => { 
    const childId = e.getAttribute('id');
    const index = idToIndex(childId);
    const value = e.value;
    const copySchema = {...schemaForm}
    if(!copySchema.children){
        copySchema.children = [];
    }
    copySchema.children[index] = {...copySchema.children[index], value: value};
    return copySchema;
}

export const getChildrenVisibility = (e:any, schemaForm) => {
    const childId = e.getAttribute('id');
    const index = idToIndex(childId);
    const value = e.checked;
    const copySchema = {...schemaForm}
    if(!copySchema.children){
        copySchema.children = [];
    }
    copySchema.children[index] = {...copySchema.children[index], visibility: value};
    return copySchema;
}

const CamposComponent = () => {
    const {setConfig} = useStateContext();
    useEffect(() => {
        setConfig(false);
    }, []);
    return (
        <div>
            <Outlet/>
        </div>
    );
}
export default CamposComponent;
