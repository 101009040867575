import React, {FC, forwardRef, useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import {toast} from "react-toastify";

interface IProps {
    name: string,
    id: string,
    label: string,
    onchange: any,
    value?: any,
    classes?: any,
    disabled?: boolean,
    notPaste?: boolean,

    mask?: string,
}

const MaskInput: FC<IProps> = forwardRef(({name, id, label, onchange, value, classes, disabled, notPaste, mask}, ref) => {
    const handlePaste = (event: any) => {
        event.preventDefault();
        toast.error("Não é possível colar um valor a esse campo.")
        return;
    }

    return (
        <div className="flex flex-col items-start bg-white rounded-xl w-full px-4 py-3 focus:outline-none focus:border focus:border-gray-600">
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
            <InputMask
                type={'text'}
                id={id}
                className={"block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:outline-none focus:border focus:border-gray-600  " + classes?.join(" ")}
                onChange={({target}: any) => onchange(target.value)}
                onPaste={!!notPaste ? handlePaste : () => true}
                name={name}
                value={value}
                placeholder="Escreva aqui..."
                mask={mask || ''}
            />
        </div>
    );
});

export default MaskInput;
