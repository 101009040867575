import React, {SyntheticEvent, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import axiosClient from "../../../services/axios";
import {GenerateMask} from "../Home";
import FormSurvey from "../../../components/Survey/Form";
import {IOptions} from "../configuracao/certs/Add";
import {useParams} from "react-router-dom";

export interface IForm {
    id?: string,
    title?: string,
    date?: string,
    company_id?: string,
    surveyor_id?: string,
    form_id?: string
}

const EditVistoriaComponent = () => {
    const [data, setData] = useState<IForm>({
        id: "",
        title: "",
        date: "",
        company_id: "",
        surveyor_id: "",
        form_id: ""
    });
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<IOptions|any>(null);
    const [selectedSurveyor, setSelectedSurveyor] = useState<IOptions|any>(null);
    const [selectedForm, setSelectedForm] = useState<IOptions|any>(null);

    const {id} = useParams();

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00")
        axiosClient.get(`/vistorias/edit/${id}`)
            .then(({data:{data:dt}}) => {
                setData({...data, id: dt?.id, date: dt?.date, title: dt?.title, company_id: dt?.company_id, surveyor_id: dt?.surveyor_id, form_id: dt.form_id})
                setSelectedCompany(dt?.company)
                setSelectedSurveyor(dt?.surveyor)
                setSelectedForm(dt?.form)
            }).catch(() => {
        })
    }, []);

    useEffect(() => {
        setCompany(selectedCompany?.value);
    }, [selectedCompany]);

    useEffect(() => {
        setSurveyor(selectedSurveyor?.value);
    }, [selectedSurveyor]);

    useEffect(() => {
        setForm(selectedForm?.value);
    }, [selectedForm]);

    const setCompany = (company: string) => setData({...data, company_id: company});
    const setSurveyor = (surveyor: string) => setData({...data, surveyor_id: surveyor});
    const setForm = (form: string) => setData({...data, form_id: form});
    const seTitle = (title: string) => setData({...data, title});
    const seDate = (date: string) => setData({...data, date});

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if(
            data?.id &&
            data?.title &&
            data?.company_id &&
            data?.surveyor_id &&
            data?.form_id
        ){
            axiosClient.post('/vistorias/update', data)
                .then(({data: {message}}) => {
                    toast.success(message);
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
            });
        }else{
            toast.error("Por favor preencher ambos os campos.");
            setLoading(false);
        }
    }

    return <FormSurvey
        form={data}
        setTitle={seTitle}
        setDate={seDate}
        selectForm={selectedForm}
        selectCompany={selectedCompany}
        selectSurveyor={selectedSurveyor}
        setSelectForm={setSelectedForm}
        setSelectCompany={setSelectedCompany}
        setSelectSurveyor={setSelectedSurveyor}
        loading={loading}
        onSubmit={onSubmit}
        btnText={"Atualizar Avaliação"}/>;
};

export default EditVistoriaComponent;
