import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import SearchField from "../../../../components/SearchField";
import ScrollYBox from "../../../../components/ScrollYBox";
import CardSkeletonComponent from "../../../../components/Skelekton/CardSkelekton";
import HeaderButton from "../../../../components/HeaderButton";
import {HiOutlineArrowCircleLeft, HiOutlinePencilAlt, HiOutlineTrash, HiPlus} from "react-icons/hi";
import Scrollbars from "react-custom-scrollbars-2";
import CertCard from "../../../../components/Cards/CertCard";
import axiosClient from "../../../../services/axios";
import ModalComponent from "../../../../components/Modal";
import {toast} from "react-toastify";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBoxFormsComponent from "../../../../components/ScrollYBoxForms";
import {useStateContext} from "../../../../contexts/StateContextProvider";

const IndexCertComponent = () => {
    const {currentUser} = useStateContext();
    const [min, setMin] = useState<number>(0);
    const [max, setMax] = useState<number>(10);
    const [loading, setLoading] = useState(true);
    const [trueMax, setTrueMax] = useState<number>(0);
    const [toDown, setToDown] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [currentId, setCurrentId] = useState<number | null>(null);
    const [cards, setCards] = useState<string[] | any[] | unknown[]>([]);
    const fetchMore = useRef<HTMLDivElement>(null);
    const doSearch = async ({target: {value}}: any) => {
        setLoading(true);
        if (value.length > 3 || value.length === 0) {
            const {data} = await axiosClient.post(`certificado/pesquisar`, {search: value});
            const zipeds = Array.from(Object.values(data));
            const countTotal = Number(zipeds.pop())
            setTrueMax(countTotal);
            setCards([
                ...Array.from(new Set([...zipeds]))
            ])
        }
        setLoading(false)
    }
    const fetchData = async () => {
        setLoading(true);
        const {data} = await axiosClient.get(`certificado/listar-certificados-gerados/${min}/${max}`);
        const zipeds = Array.from(Object.values(data));
        const countTotal = Number(zipeds.pop())
        setTrueMax(countTotal);
        if(zipeds){
            const haystick = new Set(cards.map((dt:any) => dt.val));
            const values = zipeds.filter((dt:any) => !haystick.has(dt.val))
            setCards([
                ...Array.from(new Set([...cards, ...zipeds]))
            ])
        }
        setLoading(true)
        if(firstLoad){
            setToDown(true);
        }
        setFirstLoad(true);
    }

    useEffect(() => {
        setToDown(false);
    }, [firstLoad]);

    useEffect(() => {
        fetchData();
    }, [currentUser]);

    useEffect(() => {
        let observer = new IntersectionObserver(async (entries) => {
            if (entries.some(entry => entry.isIntersecting)) {
                if (trueMax > max) {
                    fetchData();
                    setFirstLoad(false);
                }
            }
        });

        if (fetchMore.current) observer.observe(fetchMore.current);
        return () => observer.disconnect();
    }, [cards]);

    const openModal = (id: number) => {
        setModal(true);
        setCurrentId(id);
    }
    const deleteForm = () => {
        axiosClient.delete(`/certificado/deletar-zip/${currentId}`)
            .then(({data: {message}}) => {
                toast.success(message);
                setTimeout(function () {
                    return window.location.reload();
                }, 800)
            })
            .catch(() => {
            })
    }
    return (
        <div>
            <header
                className={' px-4 flex gap-4 absolute md:static top-0 left-[50%] translate-x-[-50%] translate-y-[-100%] md:transform-none'}>


            </header>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'dashboard'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'certificado/adicionar'} title={"Certificados"}>
                            <HiPlus className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent toDown={toDown}>
                <main className={'flex flex-col items-center justify-center gap-4 max-w-sm sm:max-w-2xl'}>
                    <SearchField onChange={doSearch} placeholder={'Buscar certificados'}/>
                    <ScrollYBox toDown={toDown} >
                        {!loading ?
                            (cards && cards.length > 0 ? (Array.from(new Set(cards)).map((val, index) =>
                                    <CertCard title={val} key={index} click={() => openModal(index + 1)}/>)) :
                                ("Nenhum resultado.")) :
                            <CardSkeletonComponent/>
                        }
                        <div ref={fetchMore} className={`mt-[${loading ? cards.length : 1}px]`}>
                            <div role="status">
                                {cards.length > 9 && cards.length < trueMax &&
                                    <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-red-200" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                }
                            </div>
                        </div>
                    </ScrollYBox>
                </main>
            </ScrollYBoxFormsComponent>
            <ModalComponent title={'Opções'} show={modal} setShow={setModal}>
                <ul className="my-4 space-y-3">
                    <li>
                        <a onClick={deleteForm}
                           className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                            <HiOutlineTrash className={'text-2xl'}/>
                            <span className="flex-1 ml-3 whitespace-nowrap">Excluir</span>
                        </a>
                    </li>
                </ul>
            </ModalComponent>
        </div>

    );
};

export default IndexCertComponent;
