import React, {useEffect, useRef, useState} from 'react';
import HeaderButton from "../../../components/HeaderButton";
import {HiOutlineArrowCircleLeft, HiOutlinePencilAlt, HiOutlineTrash, HiPlus} from "react-icons/hi";
import SearchField from "../../../components/SearchField";
import ScrollYBox from "../../../components/ScrollYBox";
import Card from "../../../components/Cards/Card";
import CardSkeletonComponent from "../../../components/Skelekton/CardSkelekton";
import axiosClient from "../../../services/axios";
import {toast} from "react-toastify";
import ModalComponent from "../../../components/Modal";
import LoadingIndex from "../../../components/LoadingIndex";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import useDebounce from "../../../hooks/useDebounce";
import {useStateContext} from "../../../contexts/StateContextProvider";
import ScrollYBoxFormsComponent from "../../../components/ScrollYBoxForms";

interface ICards {
    id: string,
    title: string,
    status: string
}

const IndexVistoriadorComponent = () => {
    const {currentUser} = useStateContext();
    const [loading, setLoading] = useState(true);
    const [trueMax, setTrueMax] = useState<number>(0);
    const [cards, setCards] = useState<ICards[] | any[]>([]);
    const [toDown, setToDown] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [toNext, setToNext] = useState<any>(null);
    const [modal, setModal] = useState(false);
    const [currentId, setCurrentId] = useState<string|null>(null);
    const fetchMore = useRef<HTMLDivElement>(null);
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const fetchList = async () => {
        await sleep(1000);
        if (toNext) {
            let spliceInt = -1;
            if(toNext.includes("search")){
                spliceInt = -2;
            }
            let base = '/' + toNext?.split('/').splice(spliceInt).join('/');
            if (base.includes("/list") || base.includes("/search")){
                base = base.replace("/vistoriadores", ""); 
                const axios = await axiosClient.get(`/vistoriadores${base}`);

                const scrollYBox = document.getElementById("scrollYBox");
                scrollYBox?.scroll(0, scrollYBox.clientHeight);
                return axios;
            }
        }
        return await axiosClient.get(`/vistoriadores/list?page=${currentId}`);
    }

    const fetchSearch = async (query:string) => {

        query = query.trim().replace(/\.|\/|-/g, "");
        return await axiosClient.get(`/vistoriadores/search/${query}`);
    }
    const doSearch = async ({target: {value}}: any) => {
        setLoading(false);
        if (value.length > 3 || value.length == 0) {
            const {data: {data, meta: {total}, links:{next, last}}} = await fetchSearch(value);
            setTrueMax(total);
            setToNext(next||last);
            if (data) {
                setCards([
                    ...data
                ])
            }
        }
        setLoading(true)
    }
    const fetchData = async () => {
        setLoading(false);
        const {data: {data, meta: {total}, links: {next}}} = await fetchList();
        setToNext(next);
        setTrueMax(total);
        if(data && total){
            const haystick = new Set(cards.map((dt:any) => dt.id));
            const values = data.filter((dt:any) => !haystick.has(dt.id))
            setCards([
                ...cards, ...values
            ])
        }
        setLoading(true)
        if(firstLoad){
            setToDown(true);
        }
        setFirstLoad(true);
    }

    useEffect(() => {
        setToDown(false);
    }, [firstLoad]);

    useEffect(() => {
        fetchDebounce();
    }, [currentUser]);

    useEffect(() => {
        let observer = new IntersectionObserver(async (entries) => {
            if (entries.some(entry => entry.isIntersecting)) {
                if (toNext) {
                    fetchDebounce();
                    setFirstLoad(false);
                }
            }
        });
        if (fetchMore.current) observer.observe(fetchMore.current);
        return () => observer.disconnect();
    }, [toNext]);

    const debounce = useDebounce(doSearch, 500);
    const fetchDebounce = useDebounce(fetchData, 300);
    const openModal = (id:any) => {
        setModal(true);
        setCurrentId(id);
    }
    const deleteVistoriador = () => {
        axiosClient.post("/vistoriadores/delete", {id: currentId})
            .then(({data: {message}}) => {
                toast.success(message);
                setTimeout(function (){
                    return window.location.reload();
                }, 800)
            })
            .catch(() => {})
    }
    return (
        <div>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'dashboard'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'vistoriadores/adicionar'} title={"Adicionar"}>
                            <HiPlus className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <main className={'flex flex-col items-center justify-center gap-4 max-w-sm sm:max-w-2xl'}>
                <SearchField placeholder={'Buscar vistoriador'} onChange={debounce}/>
                <ScrollYBoxFormsComponent toDown={toDown} >
                    {loading ?
                        (cards && cards.length > 0 ? Array.from(new Set(cards)).map(({id,name, email, cpf}) =>
                            <Card key={id} title={name} info={[cpf, email]} click={() => openModal(id)}/>) :
                            ("Nenhum resultado.")) :
                        <CardSkeletonComponent/>
                    }
                    {toNext ? ( <LoadingIndex fetchMore={fetchMore} /> ) : (<div className={'w-full text-center flex justify-center pb-5'}></div>)}

                </ScrollYBoxFormsComponent>
            </main>
            <ModalComponent title={'Opções'} show={modal} setShow={setModal}>
                <ul className="my-4 space-y-3">
                    <li>
                        <a href={`/vistoriadores/editar/${currentId}`} className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                            <HiOutlinePencilAlt className={'text-2xl'}/>
                            <span className="flex-1 ml-3 whitespace-nowrap">Alterar</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={deleteVistoriador}
                           className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                            <HiOutlineTrash className={'text-2xl'}/>
                            <span className="flex-1 ml-3 whitespace-nowrap">Excluir</span>
                        </a>
                    </li>
                </ul>
            </ModalComponent>
        </div>
    );
};

export default IndexVistoriadorComponent;
