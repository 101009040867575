import React, { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import axiosClient from "../../../services/axios";
import { GenerateMask } from "../Home";
import FormSurveyor from "../../../components/Surveyor/Form";
import { useParams } from "react-router-dom";

export interface IForm {
    id?: string,
    name?: string,
    email?: string,
    cpf?: string,
    mte?: string,
    fixed_phone?: string,
    cell_phone?: string,
    rg?: string,
    file_id?: string,
    certifications: []
}

const EditVistoriadorComponent = () => {
    const [form, setForm] = useState<IForm>({
        id: "",
        name: "",
        email: "",
        cpf: "",
        mte: "",
        fixed_phone: "",
        cell_phone: "",
        rg: "",
        file_id: "",
        certifications: []
    });
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00")

        axiosClient.get(`/vistoriadores/edit/${id}`)
            .then(({ data: { data } }) => {
                setForm(data);
            }).catch(() => {
            })
    }, []);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if (
            form?.name &&
            form?.email &&
            form?.cpf &&
            form?.mte &&
            form?.cell_phone &&
            form?.rg &&
            form?.cell_phone &&
            form?.certifications
        ) {
            axiosClient.post('/vistoriadores/update', { ...form, role: 3 })
                .then(({ data: { message } }) => {
                    toast.success(message);
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                });
        } else {
            toast.error("Por favor preencher ambos os campos.");
            setLoading(false);
        }
    }

    return <FormSurveyor form={form} onSubmit={onSubmit} setForm={setForm}
        loading={loading} btnText={"Atualizar Vistoriador"} />;
};

export default EditVistoriadorComponent;
