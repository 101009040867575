import React, {useEffect, useState} from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {HiIdentification, HiLockClosed, HiLogout, HiUser} from "react-icons/hi";
import {useStateContext} from "../../contexts/StateContextProvider";
import ModalComponent from "../../components/Modal";
import router from "../../router";

const DashboardLayout = () => {
    const [userRole, setUserRole] = useState("Convidado");
    const {currentUser, setUserToken, userToken, config, setConfig} = useStateContext();
    useEffect(() => {
        let role = 'Convidado';
        switch (currentUser?.role) {
            case 1:
                role = 'Administrador';
                break;
            case 2:
                role = 'Empresa';
                break;
            case 3:
                role = 'Vistoriador';
                break;
        }
        setUserRole(() => role)
    }, []);

    if (!userToken) {
        return <Navigate to={'/login'}/>
    }

    const styles = {
        avatar: {
            backgroundImage: `url("${currentUser?.avatar}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },
    };

    const logout = () => {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        if (
            !localStorage.getItem("jwt") &&
            !localStorage.getItem("user")) {
            setUserToken(null);
            setConfig(false);
            router?.navigate("/login")
        }
    }

    return (
        <div>
            <section id={"MainSection"} className={'relative overflow-x-hidden'}>
                <header className={'mx-auto bg-wave-phone-pattern bg-bottom bg-cover bg-no-repeat h-56 '}>
                    <p className={'text-9xl font-bold tracking-tight text-transparent'}>Space</p>
                </header>
                <main className={'flex flex-col max-w-sm sm:max-w-2xl gap-6 items-center justify-center relative z-30 left-1/2 -translate-x-1/2'}>
                    <header className={'w-full cursor-pointer flex justify-between gap-[100px] px-4'}>
                        <section onClick={() => setConfig(true)} className={'flex-1 bg-main-color text-white w-full flex gap-3 p-5 rounded-2xl'}>
                            {currentUser?.avatar ?
                                <div className={'bg-white rounded-full p-2 h-16 w-16'} style={styles.avatar}></div> :
                                (<div className="relative w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                    <svg className="absolute w-20 h-20 text-gray-400 -left-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
                                    </svg>
                                </div>)
                            }
                            <div className={'flex-1 flex flex-col gap-0 '}>
                                <h1 className={'font-semibold text-2xl'}>{currentUser?.name}</h1>
                                <p className={'text-lg'}>{userRole}</p>
                            </div>
                        </section>

                    </header>
                    <section className={'px-4 flex flex-col gap-3 w-full'}>
                        {<Outlet/>}
                        <ModalComponent title={'Configuração'} show={config} setShow={setConfig}>
                            <ul className="space-y-3">
                                <li>
                                    <a onClick={() => router.navigate('/meu-usuario/alterar')} className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                        <HiUser className={'text-2xl'}/>
                                        <span className="flex-1 ml-3 whitespace-nowrap">Alterar dados do usuário</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => router.navigate('/senha/alterar')} className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                        <HiLockClosed className={'text-2xl'}/>
                                        <span className="flex-1 ml-3 whitespace-nowrap">Alterar senha</span>
                                    </a>
                                </li>

                                {currentUser?.role === 1 ? (
                                    <li>
                                        <a onClick={() => router.navigate('/certificado/listar')}
                                           className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                            <HiIdentification className={'text-2xl'}/>
                                            <span className="flex-1 ml-3 whitespace-nowrap">Certificados</span>
                                        </a>
                                    </li>
                                ) : <li></li>}
                                <li>
                                    <a onClick={logout} className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                        <HiLogout className={'text-2xl text-main-color'}/>
                                        <span className="flex-1 ml-3 whitespace-nowrap">Sair</span>
                                    </a>
                                </li>
                            </ul>
                        </ModalComponent>
                    </ section>
                </main>

                <footer className={'w-full z-10 flex flex-col justify-center items-center text-sm p-5 bg-sec-color md:mb-10'}>
                © 2023 - Efatha LTDA - Todos os Direitos Reservados.
                </footer>
            </section>


        </div>
    );
};

export default DashboardLayout;
