import React, {FC, useCallback, useEffect, useRef} from 'react';

interface IProps {
    stream?: File | null,
    setStream: any,
    title: string,
    opt: string,
    accept: string,
    value?: string
}

const FileInputComponent: FC<IProps> = ({stream, setStream, title, opt, accept, value}) => {
    const bg = useRef<HTMLLabelElement | null>(null);
    const setBg = (url:string) => {
        if (bg.current) {
            bg.current.style.backgroundImage = `url('${url}')`
            bg.current.style.backgroundRepeat = 'no-repeat';
            bg.current.style.backgroundPosition = 'center';
            bg.current.style.backgroundSize = 'cover';
        }
    }
    useEffect(() => {
        if(value){
            setBg(value);
        }
    }, [value]);

    const _setStream = (file: any) => {
        setStream(file)
        if (file) {
            setBg(URL.createObjectURL(file));
        }
    };

    return (
        <div className="flex items-center justify-center w-full">
            <label ref={bg} htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-56 h-56 border-2 ${stream || value ? 'border-main-color border-solid' : 'border-gray-300 border-dashed'}
             rounded-full cursor-pointer bg-white hover:bg-white-100 shadow-md scale-90`}>
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" className={`w-10 h-10 mb-3 ${stream || value ? 'text-transparent' : 'text-gray-400'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                    </svg>
                    <p className={`mb-2 text-sm ${stream || value ? 'text-transparent' : 'text-gray-500'}`}><span className="font-semibold">{stream ? stream.name : title}</span></p>
                    {stream || value ? null : <p className="text-xs text-gray-500">{opt}</p>}
                </div>
                <input id="dropzone-file" onChange={({target: {files}}) => {
                    _setStream(files?.item(0))
                }} accept={accept} type="file" className="hidden"/>
            </label>
        </div>
    );
};

export default FileInputComponent;
