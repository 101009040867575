import {HiOutlineArrowCircleLeft, HiPlus} from "react-icons/hi";
import HeaderButton from "../../../../components/HeaderButton";
import Scrollbars from "react-custom-scrollbars-2";
import FloatingInputComponent from "../../../../components/Forms/FloatingInput";
import React, {SyntheticEvent, useEffect, useState} from "react";
import Button from "../../../../components/Forms/Button";
import {useStateContext} from "../../../../contexts/StateContextProvider";
import ImageInput from "../../../../components/Forms/ImageInput";
import axiosClient from "../../../../services/axios";
import {toast} from "react-toastify";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBox from "../../../../components/ScrollYBox";
import ScrollYBoxFormsComponent from "../../../../components/ScrollYBoxForms";

export const AlterUserInfoComponent = () => {
    const {currentUser, testToken} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [stream, setStream] = useState<File | null | undefined>(null);
    const [name, setName] = useState(currentUser?.name);
    const [email, setEmail] = useState(currentUser?.email);
    const [avatar, setAvatar] = useState<string | undefined>(currentUser?.avatar);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if (
            name &&
            email &&
            currentUser
        ) {
            const form = new FormData();
            form.append("id", `${currentUser.id}`);
            if (stream) form.append("file", stream);
            form.append("name", name);
            form.append("email", email);

            axiosClient.post('usuario/salvar-informacao', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(({data: {message}}) => {
                    toast.success(message);
                    testToken()
                })
                .catch(({response: {data}}) => {
                    if (data.error) {
                        toast.error(data.message);
                    } else {
                        Object.values(data?.errors).map((erro: object | any | any[]) => toast.error(erro[0]));
                    }
                }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            });
        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }

    }

    return (
        <div>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'/home'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'flex max-w-sm sm:max-w-2xl items-center justify-center '}>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <ImageInput value={avatar} stream={stream} setStream={setStream} title={"Clique ou arraste para anexar a sua foto de avatar"} opt={"jpg, jpeg, png, webp (MAX. 3MB)"} accept={'.jpg, .jpeg, .png, .webp'}/>
                        <FloatingInputComponent type={'text'} label={'Nome'} name={'name'} id={'usuarioNome'} value={name} onchange={setName}/>
                        <FloatingInputComponent type={'text'} label={'E-mail'} name={'email'} id={'instrutor_nome'} value={email} onchange={setEmail}/>
                        <Button loading={loading}>Alterar usuário</Button>
                    </form>
                </main>
            </ScrollYBoxFormsComponent>
        </div>

    );
};
