import React, { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import axiosClient from "../../../services/axios";
import FormFormulario from "../../../components/Formulario/Form";
import router from "../../../router";
import { IOptions } from "../configuracao/certs/Add";

export interface ISchemaForm {
    label?: string,
    name?: string,
    id?: string,
    title?: string,
    field?: string,
    pos?: string,
    classes?: string,
    properties?: string,
    type?: string,
    accept_values?: string
    children?: ISchemaForm[],
    value?: string,
    visibility?: boolean | undefined
}

export interface IForm {
    title: string,
    form: ISchemaForm[]
}

export interface ITemplateList {
    "id": string,
    "title": string,
    "path": string
}

const AddFormComponent = () => {
    const [form, setForm] = useState<IForm>({
        title: "",
        form: []
    });
    const [loading, setLoading] = useState(false);
    const [select, setSelect] = useState<IOptions | any>(null);
    const [options, setOptions] = useState<IOptions[] | null | []>(null);
    const [imported, setImported] = useState(false);
    const [templateTitle, setTemplateTitle] = useState("");
    const setTitle = (title: string) => {
        setForm({ ...form, title });
    }
    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if (form?.title && form?.form) {
            axiosClient.post('formularios/store', { ...form, file_id: select?.value })
                .then(({ data: { message } }) => {
                    toast.success(message);
                    setTimeout(function () {
                        return router?.navigate(`/formularios/listar`);
                    }, 800)
                })
                .catch(({ response: { data } }) => {
                    if (data.error) {
                        toast.error(data.message);
                    } else {
                        Object.values(data?.errors).map((erro: object | any | any[]) => toast.error(erro[0]));
                    }
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                });
        } else {
            toast.error("Por favor preencher ambos os campos.");
            setLoading(false);
        }
    }
    useEffect(() => {
        axiosClient.get("formularios/listar-template").then(({ data: { data } }) =>
            setOptions(data.map(({ title, id }: ITemplateList) =>
                ({ "label": title.length > 40 ? title.substring(0, 40) + "..." : title, "value": id }))
            )
        )
    }, [imported]);
    return <FormFormulario form={form} setForm={setForm} title={form.title} templateTitle={templateTitle} setTemplateTitle={setTemplateTitle}
        options={options} select={select} setSelect={setSelect} setImported={setImported}
        setTitle={setTitle} onSubmit={onSubmit} loading={loading}
        btnText={"Gerar Formulário"} />;
};

export default AddFormComponent;
