import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import {useStateContext} from "../../../contexts/StateContextProvider";

const FormularioComponent = () => {
    const {setConfig} = useStateContext();
    useEffect(() => {
        setConfig(false);
    }, []);
    return (
        <div>
            <Outlet/>
        </div>
    );
}
export default FormularioComponent;
