import React, { FC, useEffect, useState } from 'react';
import { HiOutlineCheck, HiOutlineX, HiPencil, HiPlus } from "react-icons/hi";
import Button from "../Forms/Button";
import FloatingInputComponent from "../Forms/FloatingInput";
import ModalComponent from '../Modal';
import FileInputComponent from '../Forms/FileInput';
import axiosClient from "../../services/axios";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TableComponent from './Table';

interface IProps {
    form: any,
    certification: any,
    setCertification: any,
    setCertifications: any
}

const backend = process.env.REACT_APP_BASE_URL + '/storage';

const CertificationComponent: FC<IProps> = ({ form, certification, setCertification, setCertifications }) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [stream, setStream] = useState<File | null>(null);
    const [higherThanZero, setHigherThanZero] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [seeForm, setSeeForm] = useState<boolean>(false);
    const [urlImg, setUrlImg] = useState<string>('');

    const setType = (type: string) => setCertification({ ...certification, type });
    const setMark = (mark: string) => setCertification({ ...certification, mark });
    const setModel = (model: string) => setCertification({ ...certification, model });
    const setSerieNumber = (serie_number: string) => setCertification({ ...certification, serie_number });
    const setFileId = (file_id: string) => setCertification({ ...certification, file_id });

    useEffect(() => {
        if (!stream) {
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: "Carregando certificado",
            html: "Aguarde um momento...",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        const form = new FormData();
        form.append("file", stream);
        form.append("title", stream.name.substring(0, 40) + "_" + Math.floor(Date.now() / 1000));
        axiosClient.post('/vistoriadores/importar-certificado', form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(({ status, data: { data } }) => {
            if (status >= 200 && status < 300) {
                if (data?.id) {
                    setFileId(data?.id);
                    setTimeout(() => MySwal.close(), 2000);
                    return;
                }
            }
            toast.error("Não foi possível enviar o certificado!")
        })
    }, [stream]);

    const exclude = (index: number) => {
        if (!form?.certifications[index]) {
            return;
        }
        const certificationsCopy = [...(form?.certifications || [])];
        certificationsCopy.splice(index, 1);
        setCertifications(certificationsCopy);
        setHigherThanZero(certificationsCopy.length > 0);
    }

    const edit = (index: number) => {
        if (!form?.certifications[index]) {
            return;
        }
        setCurrentIndex(index);
        setCertification(form?.certifications[index]);
        seeFields(true);
    }

    const save = () => {
        setLoading(!loading);
        const { type, mark, model, serie_number, file_id } = certification;
        if (type && mark && model && serie_number && file_id) {
            const certificationsCopy = [...(form?.certifications || [])];

            if (!currentIndex && currentIndex !== 0) {
                certificationsCopy.push(certification);
            } else {
                certificationsCopy[currentIndex] = certification;
            }
            setCertifications(certificationsCopy);
            setHigherThanZero(certificationsCopy.length > 0);
            setCertification({
                type: '',
                mark: '',
                model: '',
                serie_number: '',
                file_id: ''
            });
            setStream(null);
            seeFields(false);
            setUrlImg('');
            setCurrentIndex(null);
        } else {
            toast.error("Preencha ao menos uma certificação.")
        }
        setLoading(false);
    }

    useEffect(() => {
        if (form?.certifications?.length > 0) {
            setHigherThanZero(true);
        }
    }, [form]);

    const openModal = () => {
        setShow(true);
        setLoading(false);
    }

    const seeFields = (status = null) => {
        setSeeForm(status === null ? !seeForm : status);
    }

    const getUrlImg = () => {
        setUrlImg('');
    }
    useEffect(() => {
        if (seeFields) {
            if (!certification?.file_id) {
                return;
            }
            axiosClient.post('/vistoriadores/get-certificado', { file_id: certification?.file_id })
                .then(({ status, data: { data } }) => {
                    if (status >= 200 && status < 300) {
                        if (data.path) {
                            setUrlImg(`${backend}/${data.path}`);
                        }
                    }
                });
        }
    }, [seeFields]);

    return (
        <>
            <button type={'button'} data-higherthanzero={higherThanZero} onClick={openModal}
                className={'w-full flex justify-center data-[higherthanzero=true]:bg-green-600 gap-3 items-center ' +
                    'px-2 text-white h-14 rounded-lg shadow-sm bg-gray-500 hover:bg-main-color'}>
                {false ?
                    <HiOutlineCheck /> :
                    <HiPlus />} {higherThanZero ?
                        `Você configurou ${form?.certifications?.length} certificaç${form?.certifications?.length > 1 ? 'ões' : 'ão'} ` :
                        `Configure as certificações`}
            </button>
            <ModalComponent title={"Adicionar Certificação"} show={show} setShow={setShow} top={"top-1/2"}>
                <div className={'flex flex-col gap-4'}>
                    {
                        higherThanZero &&
                        <div className={'flex flex-col gap-4 bg-gray-100 p-3 rounded-lg'}>
                            <div className={'flex flex-col text-start gap-3'}>
                                <TableComponent
                                    edit={edit}
                                    exclude={exclude}
                                    headers={['Tipo', 'Modelo', 'Ações']}
                                    rows={form?.certifications?.map(({ type, model }, key) => ({ type, model, key }))} />
                            </div>
                        </div>
                    }
                    {
                        !seeForm ?
                            <div className={'flex flex-col gap-4 bg-gray-100 p-3 rounded-lg'}>
                                <Button func={seeFields} type={'button'} loading={false}>Adicionar +</Button>
                            </div> :
                            <div className={'flex flex-col gap-4 bg-gray-100 p-3 rounded-lg'}>
                                <div className={'flex justify-between flex-wrap items-center text-start gap-3'}>
                                    <FloatingInputComponent
                                        type={'text'} label={'Tipo'} name={'c_type'} id={'cType'} value={certification.type} onchange={setType} />
                                    <FloatingInputComponent
                                        type={'text'} label={'Marca'} name={'c_mark'} id={'cMark'} value={certification.mark} onchange={setMark} />
                                    <FloatingInputComponent
                                        type={'text'} label={'Model'} name={'c_module'} id={'cModel'} value={certification.model} onchange={setModel} />
                                    <FloatingInputComponent
                                        type={'text'} label={'Número de série'} name={'c_serie_number'} id={'cSerieNumber'} value={certification.serie_number} onchange={setSerieNumber} />
                                    <FileInputComponent stream={stream} opt={"JPG, JPEG, PNG (MAX. 20MB)"} accept={'.jpg, .jpeg, .png'} setStream={setStream} title={'Certificado'} url={urlImg} />
                                </div>
                                <Button func={save} type={'button'} loading={loading}>Salvar</Button>
                            </div>
                    }
                </div>
            </ModalComponent>
        </>
    );
};

export default CertificationComponent

