import {FC, ReactElement, useCallback, useEffect, useRef, useState} from 'react';


interface IProps {
    children: ReactElement,
    title: string,
    show?: boolean,
    setShow?: any,
    size?: string | null,
    top?: string
}


const ModalComponent: FC<IProps> = ({children, title, show, setShow, size, top}) => {
    const modal = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if(modal.current){
            document?.querySelector("html")?.scrollTo(0, 90)
        }
        return () => {
            if (size && ['lg'].filter((value) => value === size).length === 0) {
                throw new Error("Tamanho configurado é inválido.")
            }

        };
    }, [size, show]);

    return (
            <div ref={modal} aria-hidden={show} className={`absolute top-[90px] left-1/2 -translate-x-1/2 z-50 w-full max-w-lg hidden aria-hidden:block aria-hidden:focus`}>
                <div className={`relative bg-white rounded-lg shadow-2xl w-full max-sm:max-w-[92%] max-sm:mx-auto`}>
                    <button onClick={setShow} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="crypto-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="px-6 py-4 border-b rounded-t">
                        <h3 className="text-base font-semibold text-gray-900 lg:text-xl">
                            {title}
                        </h3>
                    </div>
                    <div className="p-6">
                        {children}
                    </div>
                </div>
            </div>
    );
};

export default ModalComponent;
