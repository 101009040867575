import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import HeaderButton from "../../../components/HeaderButton";
import { HiOutlineArrowCircleLeft, HiOutlinePencilAlt, HiOutlineTrash, HiPlus } from "react-icons/hi";
import axiosClient from "../../../services/axios";
import Button from "../../../components/Forms/Button";
import { useParams } from "react-router-dom";
import { GenerateMask } from "../Home";
import ScrollYBoxFormsComponent from "../../../components/ScrollYBoxForms";
import DynamicFullFormComponent from '../../../components/Forms/DynamicFullForm';
import { toast } from "react-toastify";
import useDebounce from '../../../hooks/useDebounce';
import SignaturePadComponent from '../../../components/Forms/SignaturePad';
import router from "../../../router";
import { useStateContext } from '../../../contexts/StateContextProvider';

interface IForm {
    field: string,
    label: string,
    name: string,
    id: string,
    classes?: string | undefined,
    properties?: string | undefined,
    accept_values?: string | undefined,
    type: string,
    children?: IForm[],
    visibility: boolean,
    value: string
}

const IndexRespostaComponent = () => {
    const { currentUser } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<IForm[]>([{
        field: "",
        label: "",
        name: "",
        id: "",
        classes: undefined,
        properties: undefined,
        accept_values: undefined,
        type: "",
        children: [],
        visibility: true,
        value: ""
    }]);
    const [answers, setAnswers] = useState<any>('');
    const [filledAllRequiredFields, setFilledAllRequiredFields] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [signature, setSignature] = useState<any>("");
    const [afterDebounce, setAfterDebounce] = useState<boolean>(false);
    const [onlyOnce, setOnlyOnce] = useState<boolean>(false);
    const [cicles, setCicles] = useState<number>(0);

    const { id } = useParams();

    useEffect(() => {
        axiosClient.post(`/respostas/content`, { id })
            .then(({ data: { data } }) => {
                setForm(data);
            }).catch((e) => {
            })
    }, []);

    const _setDefaultFieldValue = (arrayAnswers: any, array = null) => {
        if (!array) {
            array = [...form];
        }
        array.forEach((item, index) => {
            if (item.children?.length > 0) {
                arrayAnswers = _setDefaultFieldValue(arrayAnswers, item.children);
            } else {
                if (item?.value && !arrayAnswers[item.field]) {
                    arrayAnswers[item.field] = item.value;
                }
            }
        });
        return arrayAnswers;
    }

    const hidrateFields = () => {
        if (form && form?.length > 0) {
            axiosClient.post(`/respostas/answers`, { id })
                .then(({ data: { data } }) => {
                    if (!onlyOnce) {
                        const answers = JSON.parse(data?.answers);
                        let arrayAnswers = Object.values(answers);
                        arrayAnswers = _setDefaultFieldValue(answers);
                        setAnswers(arrayAnswers);
                        debounceUpdating();
                        setOnlyOnce(true);
                    }
                    return;
                }).catch((e) => null)
        }
    }

    const debounceHidrateFields = useDebounce(hidrateFields, cicles == 0 ? 250 : 2000);

    useEffect(() => {
        debounceHidrateFields();
    }, [form]);

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00");
        GenerateMask("cep", "00000-000");
        GenerateMask("cnpj", "00.000.000/0000-00");
        GenerateMask("cnae", "00.00-0-00")
        GenerateMask("fixed-phone", "(00) 0000-0000")
        GenerateMask("cell-phone", "(00) 00000-0000")
    }, [answers]);

    const onChangeStep = () => {
        setAfterDebounce(true);
    }

    useEffect(() => {
        if (afterDebounce) {
            if (!answers || currentUser?.role === 1) {
                return;
            }
            if (cicles > 0) {
                axiosClient.post('/respostas/update', { id, response: answers })
                    .then(({ status }) => status === 200 ? toast.success("Campo salvo com sucesso!") : null)
                    .catch(() => null)
                    .finally(() => {
                        setTimeout(() => {
                            setLoading(false)
                        }, 500);
                    });
            } else {
                setCicles(cicles + 1);
            }
            setAfterDebounce(false);
        }
    }, [afterDebounce])

    const debounceUpdating = useDebounce(onChangeStep, 1000);

    const handleSetFilledForm = (value: any, index: number, childIndex: any) => {
        if ( value !== undefined || value !== null ) {
            const isChildrenField = !!childIndex || childIndex === 0;
            setLoading(true);
            if (!isChildrenField) {
                setAnswers({ ...answers, [form[index].field]: value });
                if (form[index].value) {
                    const formObjectCopy = [...form];
                    formObjectCopy[index].value = value;
                    setForm([...formObjectCopy]);
                }
            } else {
                const answerObjectCopy = { ...answers };
                answerObjectCopy[form[index].children[childIndex].field] = value;
                setAnswers({ ...answerObjectCopy });
                if (form[index].children[childIndex].value) {
                    const formObjectCopy = [...form];
                    formObjectCopy[index].children[childIndex].value = value;
                    setForm([...formObjectCopy]);
                }
            }

            debounceUpdating();
        }
    };

    const handleRequiredItems = (array = null, parent = null) => {
        if (!array) {
            array = [...form];
        }
        let isValid = true;
        try {
            array.forEach((item) => {
                if (item.children?.length > 0) {
                    isValid = handleRequiredItems(item.children, item);
                } else {
                    const properties = JSON.parse(item?.properties) || [];
                    if (properties?.includes('required') || properties?.includes("Campo obrigatório")) {
                        if (item?.field && !answers[item?.field]) {
                            if (!parent) {
                                throw new Error(`O campo ${item.label} é obrigatório`);
                            }

                            if (parent) {
                                if (parent?.type !== "text_file" || item?.type !== "text") {
                                    throw new Error(`O campo ${item.label} é obrigatório`);
                                }
                            }
                        }
                    }
                }
            });
        } catch (e) {
            if (e.message) {
                toast.error(e.message);
            }
            throw new Error();
        }
        return isValid;
    }

    const handleAssignableSection = (e) => {
        if (loading == true) {
            toast.error("Aguarde o carregamento da resposta!");
            return;
        }
        setLoading(true);
        let test;
        try {
            test = handleRequiredItems();
        } catch (e) {
            test = false;
            setLoading(false);
        }

        if (test) {
            setFilledAllRequiredFields(true);
        }
    }

    const returnStepOne = () => {
        setStep(step - 1);
        setFilledAllRequiredFields(false);
    }

    useEffect(() => {
        setLoading(false);
        if (filledAllRequiredFields) {
            setStep(step + 1);
        }
    }, [filledAllRequiredFields]);

    const submitting = () => {
        if (
            answers && filledAllRequiredFields
        ) {
            axiosClient.post('/respostas/save', { id, response: answers, signature })
                .then(({ data: { message } }) => {
                    toast.success(message);
                    return router?.navigate(`/dashboard`);
                })
                .catch(() => null).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                });
        } else {
            toast.error("Por favor, retorno as perguntas e preencha todas as que forem obrigatórias.");
            setLoading(false);
        }
    }

    const debounceSubmitting = useDebounce(submitting, 1000);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        debounceSubmitting();
    }

    return (
        <div>
            <header className={'max-w-sm sm:max-w-2xl w-full flex justify-center gap-4 relative md:static md:top-0 md:left-[50%] md:translate-x-[-50%] md:translate-y-[-100%] md:transform-none'}>
                <HeaderButton url={'/home'} title={"Voltar"}>
                    <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'} />
                </HeaderButton>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'flex text-center max-w-sm sm:max-w-2xl items-center justify-center mt-4'}>
                    <section className={'w-full'}>
                        <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col h-full w-full gap-4 rounded-xl'}>
                            {
                                (!!form) &&
                                (
                                    step === 1 ?
                                        form && form?.length > 0 && form.map((item, index) => (
                                            <div key={item.field}>{
                                                !!item?.visibility ?
                                                    <DynamicFullFormComponent form={form} filled_form={answers} currentId={index} handleSetFilledForm={handleSetFilledForm} />
                                                    : ''
                                            }</div>
                                        )) :
                                        <SignaturePadComponent setSignature={setSignature} />
                                )
                            }

                            {(step === 1) && (
                                <Button type={'button'} func={handleAssignableSection} loading={loading}>Avançar</Button>
                            )}

                            {(step > 1) && (
                                <Button loading={loading}>Salvar Avaliação</Button>
                            )}

                            {(step > 1) && (
                                <button type={'button'} onClick={returnStepOne}
                                    className={'flex justify-center items-center h-16 text-xl w-full ' +
                                        'rounded-xl px-3 bg-zinc-500 hover:cursor-pointer hover:bg-zinc-500/80 text-white'}>
                                    Voltar</button>
                            )}
                        </form>
                    </section>
                </main>
            </ScrollYBoxFormsComponent>
        </div>
    );
};

export default IndexRespostaComponent;
