import ImageComponent from "./Image";
import desktop from '../assets/img/BACK03.png';
import mobile from '../assets/img/BACK02.png';

const WavesComponent = () => {
    return (
        <picture>
            <source media="(min-width:650px)" srcSet={desktop}/>
            <source media="(min-width:465px)" srcSet={mobile}/>
            <ImageComponent classes={'block w-full'} useFallback={true} imageUrl={desktop} description={"imagem de cabeçalho com objetivo puramente estético"} />
        </picture>
    );
};

export default WavesComponent;
