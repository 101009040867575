import {useCallback, useContext, useEffect} from "react";
import {useState} from "react";
import {createContext} from "react";
import {Scroll} from "scrollex";
import axiosClient from "../services/axios";

enum UserRoles {
    Guest,
    Admin,
    Company,
    Surveyor,
}

interface User {
    name: string,
    role: UserRoles,
    id?: number | undefined,
    email?: string | undefined,
    expDate?: string | undefined,
    avatar?: string | undefined
}

interface contextProps {
    currentUser: User | null | undefined,
    setCurrentUser: any,
    userToken: string,
    setUserToken: any,
    config: boolean,
    setConfig: any,
    testToken: any
}

const StateContext = createContext<contextProps>({
    currentUser: {
        name: "",
        role: UserRoles.Guest,
        id: undefined,
        email: undefined,
        expDate: undefined,
        avatar: undefined
    },
    setCurrentUser: () => {
    },
    userToken: "",
    setUserToken: () => {
    },
    config: false,
    setConfig: () => {
    },
    testToken: () => {

    }
});

export const StateContextProvider = ({children}: any) => {
    const [currentUser, setCurrentUser] = useState<User | null | undefined>(localStorage.getItem('user') ?
        JSON.parse(localStorage.getItem('user') ?? "") :
        {
            name: "",
            role: 0,
            id: undefined,
            email: undefined,
            expDate: undefined,
            avatar: undefined
        });
    const [userToken, _setUserToken] = useState(localStorage.getItem('jwt') ?? '');
    const [config, setConfig] = useState(false);
    const setUserToken = (token: string) => {
        if (token && token !== "") {
            localStorage.setItem('jwt', token);
            localStorage.setItem('expDate', JSON.stringify(new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000)));
        } else {
            localStorage.removeItem('expDate');
            localStorage.removeItem('jwt');
        }
        _setUserToken(token);
    }
    const testToken = useCallback(
        () => {
            axiosClient.get('/auth/me').then(({data: {data}}) => {
                setCurrentUser(data);
                data.expDate = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
                localStorage.setItem('user', JSON.stringify(data));
            }).catch(() => {
                setUserToken("");
                localStorage.removeItem('expDate');
                localStorage.removeItem('jwt');
                setCurrentUser({
                    name: "",
                    role: 0,
                    id: undefined,
                    email: undefined,
                    avatar: undefined,
                    expDate: undefined
                });
                localStorage.removeItem('user');
            })
        },
        [],
    );

    useEffect(() => {
        const user = localStorage.getItem('user');
        const expDateToken = localStorage.getItem('expDate');
        try {
            if (userToken && expDateToken && ((new Date() > new Date(JSON.parse(expDateToken))))) {
                if (user) {
                    if ((new Date() > new Date(JSON.parse(user).expDate))) {
                        setUserToken("");
                        localStorage.removeItem('jwt');
                        localStorage.removeItem('user');
                    }
                    return;
                }
            }
        } catch (e) {
        }
        testToken()
    }, []);
    return (
        <StateContext.Provider
            value={{currentUser, setCurrentUser, userToken, setUserToken, config, setConfig, testToken}}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
