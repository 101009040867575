
import React, {useEffect, useRef} from "react";
import Scrollbars from "react-custom-scrollbars-2";

const ScrollYBoxFormsComponent = ({children, toDown}: any) => {
    const scrollYBox = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if(toDown){
            if(scrollYBox?.current){
                document?.querySelector("html")?.scrollTo(0, 2*scrollYBox.current.scrollHeight/3)
            }
        }
    }, [toDown]);
    return (
        <div ref={scrollYBox} id={"scrollYBox"} className={'w-full flex flex-col justify-between gap-3 min-h-full'}>
            {children}
        </div>
    );
};

export default ScrollYBoxFormsComponent;
