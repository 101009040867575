import React, { FC } from 'react';
import HeaderButton from "../HeaderButton";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import FloatingInputComponent from "../Forms/FloatingInput";
import SelectField from "../Forms/SelectField";
import OptionForm from "../../components/Formulario/OptionForm";
import Button from "../Forms/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import ScrollYBoxFormsComponent from "../ScrollYBoxForms";
import FieldSet from "../Formulario/FieldSet";
import Checkbox from '../Forms/Checkbox';

interface IProps {
    onSubmit: any,
    schemaForm: any,
    setSchemaForm: any,
    handleLabel: any,
    handleName: any,
    handleType: any,
    handleClasses: any,
    handleProperties: any,
    loading: boolean,
    handleChildLabel: any,
    handleChildName: any,
    handleVisibility: any,
    handleValue: any,
    handleChildValue: any,
    handleChildVisibility: any
}

const defaultClasses = [
    { value: "cpf", label: "CPF" },
    { value: "cnpj", label: "CNPJ" },
    { value: "cep", label: "CEP" },
    { value: "cnae", label: "CNAE" },
    { value: "data", label: "Data" },
    { value: "fixed-phone", label: "Telefone Fixo" },
    { value: "cell-phone", label: "Telefone Celular" }
];
const defaultProperties = [
    { value: "required", label: "Campo obrigatório" }
];
const FieldComponent: FC<IProps> = ({
    onSubmit, schemaForm, setSchemaForm,
    handleLabel, handleName,
    handleType, handleClasses, handleProperties,
    handleVisibility, handleValue,
    loading, handleChildLabel, handleChildName,
    handleChildValue,
    handleChildVisibility
}) => {
    const handleId = (value) => null
    return (
        <>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton goBack={true} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'} />
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'max-w-sm sm:max-w-2xl flex items-center justify-center mt-3'}>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4'}>
                        <div className={'text-start flex flex-wrap gap-6 md:gap-4'}>
                            <FloatingInputComponent
                                type={'text'} label={'Rótulo*'} name={'label'} id={'label'} value={schemaForm.label} onchange={handleLabel} />
                            <SelectField name={'type'} id={'type'} label={'Selecione Tipo*'} value={schemaForm.type} onchange={handleType}>
                                <>
                                    <option key={1} value="text">Campo aberto</option>
                                    <option key={2} value="checkbox">Campo sim ou não</option>
                                    <option key={3} value="select">Campo com opções</option>
                                    <option key={4} value="file">Campo de imagem</option>
                                    <option key={5} value="checkbox_na">Campo sim, não e N/A</option>
                                    <option key={6} value="text_file">Campo imagem e texto</option>
                                    <option key={7} value="three_text">Campo de texto triplo</option>
                                    <option key={8} value="select_double_text">Campo com opções + Campo de texto duplo</option>
                                    <option key={9} value="presente_ausente_na_double_text">Presente, ausente e N/A + Campo de texto duplo</option>
                                </>
                            </SelectField>
                            {schemaForm?.type === 'select' && <OptionForm values={
                                Array.isArray(schemaForm?.accept_values) ?
                                    schemaForm?.accept_values :
                                    !!schemaForm?.accept_values ? JSON.parse(schemaForm?.accept_values) : undefined
                            } form={setSchemaForm} />}
                            {
                                !!schemaForm?.type && schemaForm?.type === 'text_file' ? (
                                    <>
                                        <FieldSet legend={"Imagem e Texto"}>
                                            <main className={'flex flex-col gap-4'}>
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo Imagem'} name={'child_01_rotulo'} id={'FirstRotulo'}
                                                    value={schemaForm?.children?.[0]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo de Imagem'} name={'child_01_name'} id={'FirstName'}
                                                    value={schemaForm?.children?.[0]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo de Imagem'} disabled={true} name={'child_01_id'} id={'FirstId'}
                                                    value={schemaForm?.children?.[0]?.id} onchange={handleId} freeTarget={true} />

                                                <Checkbox name={'visibility'} id={'FirstVisibility'} label={'Campo visível'}
                                                    checked={schemaForm?.children?.[0]?.visibility !== undefined ? schemaForm?.children?.[0]?.visibility : true}
                                                    onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo de Imagem'} name={'child_01_value'} id={'FirstValue'}
                                                    value={schemaForm?.children?.[0]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo Texto'} name={'child_02_rotulo'} id={'SecondRotulo'}
                                                    value={schemaForm?.children?.[1]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo de Texto'} name={'child_02_name'} id={'SecondName'}
                                                    value={schemaForm?.children?.[1]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo de Texto'} disabled={true} name={'child_02_id'} id={'SecondId'}
                                                    value={schemaForm?.children?.[1]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'SecondVisibility'} label={'Campo visível'}
                                                    checked={schemaForm?.children?.[1]?.visibility !== undefined ? schemaForm?.children?.[1]?.visibility : true} 
                                                    onchange={handleChildVisibility} freeTarget={true} />
                                                <input type="hidden" name="child_02_value" value={schemaForm?.children?.[1]?.value} onChange={handleChildValue} id={'SecondValue'} />
                                            </main>
                                        </FieldSet>
                                    </>
                                ) : !!schemaForm?.type && schemaForm?.type === 'three_text' ? (
                                    <>
                                        <FieldSet legend={"Campo Triplo"}>
                                            <main className={'flex flex-col gap-4'}>
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 1*'} name={'child_01_rotulo'} id={'FirstRotulo'}
                                                    value={schemaForm?.children?.[0]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 1*'} name={'child_01_name'} id={'FirstName'}
                                                    value={schemaForm?.children?.[0]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 1'} disabled={true} name={'child_01_id'} id={'FirstId'}
                                                    value={schemaForm?.children?.[0]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'FirstVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[0]?.visibility !== undefined ? schemaForm?.children?.[0]?.visibility : true} 
                                                onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 1'} name={'child_01_value'} id={'FirstValue'}
                                                    value={schemaForm?.children?.[0]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 2*'} name={'child_02_rotulo'} id={'SecondRotulo'}
                                                    value={schemaForm?.children?.[1]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 2*'} name={'child_02_name'} id={'SecondName'}
                                                    value={schemaForm?.children?.[1]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 2'} disabled={true} name={'child_02_id'} id={'SecondId'}
                                                    value={schemaForm?.children?.[1]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'SecondVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[1]?.visibility !== undefined ? schemaForm?.children?.[1]?.visibility : true} 
                                                onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 2'} name={'child_02_value'} id={'FirstValue'}
                                                    value={schemaForm?.children?.[1]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 3*'} name={'child_03_rotulo'} id={'ThirdRotulo'}
                                                    value={schemaForm?.children?.[2]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 3*'} name={'child_03_name'} id={'ThirdName'}
                                                    value={schemaForm?.children?.[2]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 3'} disabled={true} name={'child_03_id'} id={'ThirdId'}
                                                    value={schemaForm?.children?.[2]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'ThirdVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[2]?.visibility !== undefined ? schemaForm?.children?.[2]?.visibility : true} 
                                                onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 3'} name={'child_03_value'} id={'ThirdValue'}
                                                    value={schemaForm?.children?.[2]?.value} onchange={handleChildValue} freeTarget={true} />
                                            </main>
                                        </FieldSet>
                                    </>
                                ) : !!schemaForm?.type && schemaForm?.type === 'select_double_text' ? (
                                    <>
                                        <FieldSet legend={"Campo com opções + Campo de texto duplo"}>
                                            <main className={'flex flex-col gap-4'}>
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo de Opções *'} name={'child_01_rotulo'} id={'FirstRotulo'}
                                                    value={schemaForm?.children?.[0]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo de Opções *'} name={'child_01_name'} id={'FirstName'}
                                                    value={schemaForm?.children?.[0]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo de Opções'} disabled={true} name={'child_01_id'} id={'FirstId'}
                                                    value={schemaForm?.children?.[0]?.id} onchange={handleId} freeTarget={true} />
                                                {<OptionForm values={
                                                    Array.isArray(schemaForm?.accept_values) ?
                                                        schemaForm?.accept_values :
                                                        !!schemaForm?.accept_values ? JSON.parse(schemaForm?.accept_values) : undefined
                                                } form={setSchemaForm} />}
                                                <Checkbox name={'visibility'} id={'FirstVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[0]?.visibility !== undefined ? schemaForm?.children?.[0]?.visibility : true} 
                                                onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo de Opções'} name={'child_01_value'} id={'FirstValue'}
                                                    value={schemaForm?.children?.[0]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 1*'} name={'child_02_rotulo'} id={'SecondRotulo'}
                                                    value={schemaForm?.children?.[1]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 1 *'} name={'child_02_name'} id={'SecondName'}
                                                    value={schemaForm?.children?.[1]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 1'} disabled={true} name={'child_02_id'} id={'SecondId'}
                                                    value={schemaForm?.children?.[1]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'SecondVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[1]?.visibility !== undefined ? schemaForm?.children?.[1]?.visibility : true} 
                                                onchange={handleChildVisibility} freeTarget={true} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 1'} name={'child_02_value'} id={'SecondValue'}
                                                    value={schemaForm?.children?.[1]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 2*'} name={'child_03_rotulo'} id={'ThirdRotulo'}
                                                    value={schemaForm?.children?.[2]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 2*'} name={'child_03_name'} id={'ThirdName'}
                                                    value={schemaForm?.children?.[2]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 2'} disabled={true} name={'child_03_id'} id={'ThirdId'}
                                                    value={schemaForm?.children?.[2]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'ThirdVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[2]?.visibility !== undefined ? schemaForm?.children?.[2]?.visibility : true} 
                                                onchange={handleVisibility} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 2'} name={'child_03_value'} id={'ThirdValue'}
                                                    value={schemaForm?.children?.[2]?.value} onchange={handleChildValue} freeTarget={true} />
                                            </main>
                                        </FieldSet>
                                    </>
                                ) : !!schemaForm?.type && schemaForm?.type === 'presente_ausente_na_double_text' ? (
                                    <>
                                        <FieldSet legend={"Campo com opções + Campo de texto duplo"}>
                                            <main className={'flex flex-col gap-4'}>
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo de Opções *'} name={'child_01_rotulo'} id={'FirstRotulo'}
                                                    value={schemaForm?.children?.[0]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo de Opções *'} name={'child_01_name'} id={'FirstName'}
                                                    value={schemaForm?.children?.[0]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo de Opções'} disabled={true} name={'child_01_id'} id={'FirstId'}
                                                    value={schemaForm?.children?.[0]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'FirstVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[0]?.visibility !== undefined ? schemaForm?.children?.[0]?.visibility : true} 
                                                onchange={handleVisibility} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo de Opções'} name={'child_01_value'} id={'FirstValue'}
                                                    value={schemaForm?.children?.[0]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 1*'} name={'child_02_rotulo'} id={'SecondRotulo'}
                                                    value={schemaForm?.children?.[1]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 1 *'} name={'child_02_name'} id={'SecondName'}
                                                    value={schemaForm?.children?.[1]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 1'} disabled={true} name={'child_02_id'} id={'SecondId'}
                                                    value={schemaForm?.children?.[1]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'SecondVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[1]?.visibility !== undefined ? schemaForm?.children?.[1]?.visibility : true} 
                                                onchange={handleVisibility} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 1'} name={'child_02_value'} id={'SecondValue'}
                                                    value={schemaForm?.children?.[1]?.value} onchange={handleChildValue} freeTarget={true} />
                                                <hr />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Rótulo Campo 2*'} name={'child_03_rotulo'} id={'ThirdRotulo'}
                                                    value={schemaForm?.children?.[2]?.label} onchange={handleChildLabel} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Nome Campo 2*'} name={'child_03_name'} id={'ThirdName'}
                                                    value={schemaForm?.children?.[2]?.name} onchange={handleChildName} freeTarget={true} />
                                                <FloatingInputComponent
                                                    type={'text'} label={'Identificação Campo 2'} disabled={true} name={'child_03_id'} id={'ThirdId'}
                                                    value={schemaForm?.children?.[2]?.id} onchange={handleId} freeTarget={true} />
                                                <Checkbox name={'visibility'} id={'ThirdVisibility'} label={'Campo visível'} 
                                                checked={schemaForm?.children?.[2]?.visibility !== undefined ? schemaForm?.children?.[2]?.visibility : true} 
                                                onchange={handleVisibility} />
                                                <FloatingInputComponent type={'text'} label={'Valor Padrão para Campo 2'} name={'child_03_value'} id={'ThirdValue'}
                                                    value={schemaForm?.children?.[2]?.value} onchange={handleChildValue} freeTarget={true} />
                                            </main>
                                        </FieldSet>
                                    </>
                                ) : (
                                    <>
                                        <FloatingInputComponent
                                            type={'text'} label={'Nome*'} name={'name'} id={'name'} value={schemaForm.name} onchange={handleName} />
                                        <FloatingInputComponent
                                            type={'text'} label={'Identificação'} disabled={true} name={'id'} id={'id'} value={schemaForm.id} onchange={handleId} />
                                    </>
                                )
                            }
                            <SelectField name={'classes'} id={'classes'} label={'Selecione estilo(s)'} isMultiple={true} onchange={handleClasses}>
                                <>
                                    {defaultClasses?.map(({ label, value }, key) =>
                                        !!(schemaForm?.classes?.includes(label)) ?
                                            (<option key={key} selected defaultValue={value}>{label}</option>) :
                                            (<option key={key} defaultValue={value}>{label}</option>)
                                    )}
                                </>
                            </SelectField>
                            <SelectField name={'properties'} id={'properties'} label={'Selecione regra(s)'} isMultiple={true} onchange={handleProperties}>
                                <>
                                    {defaultProperties?.map(({ label, value }, key) =>
                                        !!(schemaForm?.properties?.includes(label)) ?
                                            (<option key={key} selected defaultValue={value}>{label}</option>) :
                                            (<option key={key} defaultValue={value}>{label}</option>))}
                                </>
                            </SelectField>

                            {
                                !schemaForm?.type || !Array.from([
                                    'text_file', 'three_text', 'select_double_text', 'presente_ausente_na_double_text'
                                ]).includes(schemaForm?.type) ? (
                                    <>
                                        <Checkbox name={'visibility'} id={'visibility'} label={'Campo visível'} 
                                        checked={schemaForm?.visibility !== undefined ? schemaForm?.visibility : true} 
                                        onchange={handleVisibility} freeTarget={true} />
                                        <FloatingInputComponent type={'text'} label={'Valor padrão para o campo'} name={'value'} id={'value'}
                                            value={schemaForm?.value} onchange={handleValue} freeTarget={true} />
                                    </>
                                ) : ''
                            }
                        </div>
                        <Button loading={loading}>Salvar</Button>
                    </form>

                </main>
            </ScrollYBoxFormsComponent>
        </>
    );
};

export default FieldComponent;
