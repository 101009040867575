
import React, {useEffect, useRef} from "react";
import Scrollbars from "react-custom-scrollbars-2";

const ScrollYBoxComponent = ({children, toDown}: any) => {
    const scrollYBox = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if(toDown){
            if(scrollYBox?.current){
                scrollYBox.current.scrollTo(0, 2*scrollYBox.current.scrollHeight/3)
            }
        }
    }, [toDown]);
    return (
        <div ref={scrollYBox} id={"scrollYBox"} className={'w-full flex flex-col justify-between gap-3 max-h-7xl max-h-[600px] md:max-h-[400px] overflow-y-scroll'}>
            {children}
        </div>
    );
};

export default ScrollYBoxComponent;
