import React, {FC, SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react';
import {HiPencil, HiPlus} from "react-icons/hi";
import Modal from "../Modal";
import FileInput from "../Forms/FileInput";
import Button from "../Forms/Button";
import {IOptions} from "../../views/dashboard/configuracao/certs/Add";
import axiosClient from "../../services/axios";
import FloatingInputComponent from "../Forms/FloatingInput";
import {toast} from "react-toastify";
import Select from "../../pkg/react-tailwindcss-select";
import FieldSet from "./FieldSet";

interface IProps {
    options?: IOptions[] | null,
    select: IOptions,
    setSelect: any,
    setImported: any,
    title: any
    setTitle: any
}

const url = process.env.REACT_APP_BASE_URL;
const CertTemplatesComponent: FC<IProps> = ({options, select, setSelect, setImported, title, setTitle}) => {
    const [stream, setStream] = useState<File | undefined | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState<null | string>(null);

    useEffect(() => {
        setImported(false);
    }, [show]);

    const onsubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        if (
            stream &&
            title
        ) {
            const url = {endpoint: "importar-template"},
                form = new FormData();
            form.append("file", stream, 'file.docx');
            form.append("title", title);

            if(id){
                form.append("id", id);
                url.endpoint = "update-template"
            }
            axiosClient.post(`formularios/${url.endpoint}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(({status, data: {data}}) => {
                if (status >= 200 && status < 300) {
                    setShow(false);
                    setImported(true);
                    setStream(null);
                    setSelect(data?.path)
                    return;
                }
                toast.error("Preencha os campos abaixo!")
            }).finally(() => setLoading(false))
        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }
    }

    const editTemplate = (id:any) => {
        setLoading(true);
        axiosClient.get(`formularios/editar-template/${id}`).then(({status, data: {data}}) => {
            if (status >= 200 && status < 300) {
                setTitle(data.title)
                setId(data.id);
                setShow(!show);
                return;
            }
        }).finally(() => setLoading(false))
        return;
    }

    return (
        <>
            <FieldSet legend={"Template"}>
                <div className={'flex justify-between text-start gap-3'}>
                    <Select primaryColor={"red"}
                            placeholder={"Selecione"}
                            isSearchable
                            noOptionsMessage={"Nenhum resultado encontrado."}
                            value={select}
                            onChange={setSelect}
                            loading={!options}
                            options={options ? options : []}/>
                    <div className={'flex flex-col h-16 gap-2 justify-center items-stretch'}>
                        <button type={'button'} onClick={() => setShow(!show)}
                                className={'flex justify-between items-center h-full px-2 py-1 text-gray-500 rounded shadow-sm transition-all duration-300 focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}><HiPlus/>
                        </button>
                        {
                            select && (
                                <button type={'button'} onClick={() => editTemplate(select?.value) }
                                        className={'flex justify-between items-center h-full px-2 py-1 text-gray-500 rounded shadow-sm transition-all duration-300 ' +
                                            'focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20'}>
                                    <HiPencil/>
                                </button>
                            )
                        }
                    </div>
                </div>
            </FieldSet>
            <Modal title={"Importar Template"} show={show} setShow={setShow} top={'top-1/3'}>
                <form action={"#"} onSubmit={onsubmit} className={'flex flex-col gap-4'}>
                    <div className="relative w-full" >
                        <input
                            type={'text'}
                            id={'title'}
                            className={"block rounded-xl w-full px-4 pt-1 h-16 focus:outline-none focus:border focus:border-gray-600 peer "}
                            onChange={({ target }: any) => setTitle(target.value)}
                            name={'template'}
                            value={title}
                            placeholder=" "
                        />
                        <label htmlFor={'title'}
                                className={`
                            absolute
                            peer-placeholder-shown:scale-100
                            peer-placeholder-shown:-translate-y-1/2
                            peer-placeholder-shown:top-1/2
                            peer-placeholder-shown:font-normal
                            peer-placeholder-shown:translate-x-3
                            peer-placeholder-shown:text-lg
                            peer-placeholder-shown:text-gray-500
                            text-xs
                            text-gray-500
                            duration-300
                            transform
                            -translate-y-2
                            top-4
                            z-5
                            left-3.5
                            bg-transparent
                            rounded-full
                        `}>Titulo Template</label>
                    </div>
                    <FileInput stream={stream} setStream={setStream} title={"Template"} accept={".doc,.docx"} opt={"Word ex.: .docx, .doc"}/>
                    <Button loading={loading}>Registrar</Button>
                </form>
            </Modal>
        </>
    );
};

export default CertTemplatesComponent;
