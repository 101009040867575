import {FC, ReactElement} from "react";
import router from "../router";

interface IProps {
    children: ReactElement,
    title: string,
    url?: string,
    goBack?: boolean | null
}

const HeaderButtonComponent: FC<IProps> = ({children, title, url, goBack}) => {
    const goto = () => {
        if(goBack)
            return router?.navigate(-1);
        return router?.navigate(`/${url}`);
    }
    return (
        <div
            onClick={goto}
            className={'w-full flex flex-col justify-center items-center rounded-2xl p-3 border-3 bg-white inline-block z-0 cursor-pointer'}>
            {children}
            <p className={'font-semibold text-sm'}>{title}</p>
        </div>
    );
};

export default HeaderButtonComponent;
