import React, {FC} from 'react';
interface IProps {
    children:any,
    legend:string
}
const FieldSetComponent:FC<IProps> = ({children, legend}) => {
    return (
        <fieldset className={'border border-gray-500 rounded-xl shadow-sm p-3 mb-4 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 w-full'}>
            <legend className={'text-gray-500'}>{legend}</legend>
            {children}
        </fieldset>
    );
};

export default FieldSetComponent;
