import React from 'react';
import SelectField from "../Forms/SelectField";
import TextResponse from "./Text";

const PresenteAusenteNA = ({form, filled_form, currentId, mask, handle}) => {
    return (
        <div className={'flex flex-col gap-4'}>
            <SelectField name={form[currentId]?.children[0]?.name} id={form[currentId]?.children[0]?.id}
                         label={form[currentId]?.children[0]?.label} value={filled_form[String(form[currentId]?.children[0]?.field)]}
                         onchange={({value}: any) => handle(value, currentId, 0)}>
                <>
                    <option>Presente</option>
                    <option>Ausente</option>
                    <option>N/A</option>
                </>
            </SelectField>
            <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={1}/>
            <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={2}/>
        </div>
    );
};

export default PresenteAusenteNA;
