import React, {SyntheticEvent, useState} from 'react';
import {ISchemaForm} from "../formularios/Add";
import axiosClient from "../../../services/axios";
import {toast} from "react-toastify";
import router from "../../../router";
import Field from "../../../components/Field/Field";
import {getChildrenLabel, getChildrenValue, getChildrenValues, getChildrenVisibility, idToIndex} from "./Campos";

const AddCampoComponent = () => {
    const [show, setShow] = useState<boolean>(false);
    const [schemaForm, setSchemaForm] = useState<ISchemaForm>({
        label: "",
        name: "",
        id: "",
        children: [],
        visibility: undefined,
        value: ""
    });
    const [loading, setLoading] = useState(false);
    const _setSchemaForm = (up: any) => {
        setSchemaForm(schemaForm => ({
            ...schemaForm,
            ...up
        }));
    }
    const handleLabel = (e: string) => {
        const updatedValue = {label: e};
        _setSchemaForm(updatedValue)
    }

    const handleName = (e: string) => {
        const updatedValue = {
            name: e,
            id: e.replaceAll(/\s/g, "_").split("_").map((row, key) => {
                    const array = row.split("");
                    if(key > 0){
                        array[0] = array[0]?.toUpperCase();
                    }else{
                        array[0] = array[0]?.toLowerCase();
                    }
                    return array.join("");
                }).join("")
        };
        _setSchemaForm(updatedValue)
    }

    const handleChildName = (e:any) => {
        const copy = getChildrenValues(e, schemaForm);
        if(!!copy) _setSchemaForm(() => ({...copy}))
    }

    const handleChildLabel = (e:any) => {
        const copy = getChildrenLabel(e, schemaForm);
        if(!!copy) _setSchemaForm(() => ({...copy}))
    }

    const handleChildValue = (e: any) => {
        const copy = getChildrenValue(e, schemaForm);
        if(!!copy) _setSchemaForm(() => ({...copy}))
    }

    const handleChildVisibility = (e: any) => {
        const copy = getChildrenVisibility(e, schemaForm);
        if(!!copy) _setSchemaForm(() => ({...copy}))
    }

    const handleType = (e: any) => {
        const updatedValue = {type: e.value};
        _setSchemaForm(updatedValue)
    }

    const handleValue = (e: any) => {
        const updatedValue = {value: e.value};
        _setSchemaForm(updatedValue)
    }

    const handleVisibility = (e: any) => {
        const updatedValue = {visibility: e.checked};
        _setSchemaForm(updatedValue)
    }

    const handleClasses = (e: any) => {
        const values: string[] = [];
        Array.from(e.selectedOptions).forEach((e: any) => values.push(e.value))
        const updatedValue = {classes: values};
        _setSchemaForm(updatedValue)
    }

    const handleProperties = (e: any) => {
        const values: string[] = [];
        Array.from(e.selectedOptions).forEach((e: any) => values.push(e.value))
        const updatedValue = {properties: values};
        _setSchemaForm(updatedValue)
    }
    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        if (
            schemaForm?.label &&
            schemaForm?.type &&
            (schemaForm?.name || schemaForm?.children.filter((row) => !!row?.name && row?.name?.length > 1).length > 0)
        ) {
            const data = {
                ...schemaForm,
                classes: JSON.stringify(schemaForm.classes),
                properties: JSON.stringify(schemaForm.properties),
                accept_values: JSON.stringify(schemaForm.accept_values)
            }
            axiosClient.post('/campos/store', data)
                .then(({data: {message}}) => {
                    toast.success(message);
                    setSchemaForm({
                        label: "",
                        name: "",
                        id: ""
                    });
                    setTimeout(function () {
                        router?.navigate('/campos/listar')
                    }, 800)
                })
                .catch((e) => {
                    if(!!e && !!e?.response){
                        const {data} = e?.response;
                        if (data.error) {
                            toast.error(data.message);
                        } else {
                            Object.values(data?.errors).map((erro: object | any | any[]) => toast.error(erro[0]));
                        }
                    }
                }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            });

        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }

    }
    return <Field handleClasses={handleClasses} handleLabel={handleLabel} loading={loading} handleName={handleName}
                  handleProperties={handleProperties} onSubmit={onSubmit} handleType={handleType} schemaForm={schemaForm} setSchemaForm={setSchemaForm}
                  handleValue={handleValue} handleVisibility={handleVisibility} handleChildLabel={handleChildLabel} handleChildName={handleChildName}
                  handleChildValue={handleChildValue} handleChildVisibility={handleChildVisibility}
                  />;
};

// @ts-ignore
export default AddCampoComponent;
