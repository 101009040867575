import React, { FC, useEffect, useState } from 'react';
import { HiArrowSmLeft, HiArrowSmRight, HiChevronDoubleLeft, HiChevronDoubleRight, HiArrowSmUp, HiArrowSmDown, HiChevronDoubleUp, HiChevronDoubleDown } from "react-icons/hi";
import axiosClient from "../../services/axios";
import DualListBox, { Option } from "react-dual-listbox";
import { useStateContext } from "../../contexts/StateContextProvider";
import FieldSet from './FieldSet';
import { Tooltip as ReactTooltip } from "react-tooltip";
import uuid from 'react-uuid';
import useDebounce from '../../hooks/useDebounce';

interface ISchemaForm {
    field?: string,
    title?: string,
    pos?: number
}

interface IProps {
    form: ISchemaForm[] | any,
    setForm: any,
    fieldsetTitle: string
}

const SchemaFormComponent: FC<IProps> = ({ form, fieldsetTitle, setForm }) => {
    const { currentUser } = useStateContext();
    const [firstLoad, setFirstLoad] = useState(true);
    const [selected, setSelected] = useState<string[]>([]);
    const [options, setOptions] = useState<Option<string>[]>([{ value: '', label: '' }]);
    const [listSchemaForm, setListSchemaForm] = useState<ISchemaForm[] | null>(null);

    useEffect(() => {
        setTimeout(() => {
            axiosClient.get('/formularios/schema-field/list').then(({ data: { data } }) => {
                setListSchemaForm(data);
            });
        }, 1000);
    }, []);

    useEffect(() => {
        if (listSchemaForm) {
            const newOptions: any = listSchemaForm?.map((row) => {
                return { value: row.field, label: row.title }
            });
            setOptions(newOptions);
            if (!!form && form.length > 0 && firstLoad) {
                const allSelected = form.map?.((row: any) => {
                    return row.field
                });
                setSelected(allSelected);
                setFirstLoad(false);
            }
        }
    }, [listSchemaForm, form, currentUser]);

    useEffect(() => {
        if (selected.length > 0) {
            const allSelected = selected?.map((row: any, key: number) => {
                return { field: row, pos: key }
            })
            setForm((content: any) => ({ ...content, form: allSelected }));
        }
    }, [selected]);

    useEffect(() => {
        if (!!options && options?.length > 0) {
            options.map(({ value, label }: any) => {
                if (!!value && !!listSchemaForm) {
                    document?.querySelector(`[value="${value}"]`)?.setAttribute("data-tooltip-id", `${value}`)
                }
            })
        }
    }, [listSchemaForm, selected, currentUser]);


    const [lang, setLang] = useState<any>({
        filterPlaceholder: 'Pesquisar...',
        availableFilterHeader: 'Filtrar disponíveis',
        availableHeader: 'Disponíveis',
        moveAllLeft: 'Mover todos para disponíveis',
        moveAllRight: 'Mover todos para selecionados',
        moveLeft: 'Mover para disponíveis',
        moveRight: 'Mover para selecionados',
        moveBottom: 'Mover para o final',
        moveDown: 'Mover para baixo',
        moveUp: 'Mover para cima',
        moveTop: 'Mover para o início',
        noAvailableOptions: 'Nenhuma opção disponível',
        noSelectedOptions: 'Nenhuma opção selecionada',
        requiredError: 'Por favor, selecione pelo menos uma opção.',
        selectedFilterHeader: 'Filtrar selecionados',
        selectedHeader: 'Selecionados',
    });

    // @ts-ignore
    return (
        <>
            <FieldSet legend={fieldsetTitle}>
                <DualListBox
                    className={'h-80'}
                    options={options}
                    lang={lang}
                    icons={{
                        moveLeft: [<HiArrowSmLeft />],
                        moveAllLeft: <HiChevronDoubleLeft />,
                        moveRight: <HiArrowSmRight />,
                        moveAllRight: <HiChevronDoubleRight />,
                        moveDown: <HiArrowSmDown />,
                        moveUp: <HiArrowSmUp />,
                        moveTop: <HiChevronDoubleUp />,
                        moveBottom: <HiChevronDoubleDown />
                    }}
                    preserveSelectOrder
                    showOrderButtons
                    canFilter
                    selected={selected}
                    onChange={setSelected}
                />
            </FieldSet>
            {
                !!options && options?.length > 0 ? options.map(({ value, label }: any) => (
                    <ReactTooltip
                        key={String(uuid())}
                        id={value}
                        place="top"
                        content={label}
                    />
                )
                ) : null
            }
        </>
    );
};

export default SchemaFormComponent;
