import React, { FC, forwardRef } from 'react';
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

interface IProps {
    type: string,
    name: string,
    id: string,
    label: string,
    onchange: any,
    value?: any,
    classes?: any,
    disabled?: boolean,
    notPaste?: boolean,

    mask?: string,
    freeTarget?: boolean,
}

const FloatingInputComponent: FC<IProps> = forwardRef(({ type, name, id, label, onchange, value, classes, disabled, notPaste, mask, freeTarget }, ref) => {
    const handlePaste = (event: any) => {
        event.preventDefault();
        toast.error("Não é possível colar um valor a esse campo.")
        return;
    }
    // @ts-ignore
    return (
        <div className="relative w-full" >
            <InputMask
                type={type}
                id={id}
                disabled={!!disabled}
                className={"block rounded-xl w-full px-4 pt-1 h-16 focus:outline-none focus:border focus:border-gray-600 peer " + classes?.join(" ")}
                onChange={({ target }: any) => onchange(!!freeTarget ? target : target.value)}
                onPaste={!!notPaste ? handlePaste : () => true}
                name={name}
                value={value}
                placeholder=" "
                mask={mask || ''}
            />
            <label htmlFor={id}
                className={`
                        absolute
                        peer-placeholder-shown:scale-100
                        peer-placeholder-shown:-translate-y-1/2
                        peer-placeholder-shown:top-1/2
                        peer-placeholder-shown:font-normal
                        peer-placeholder-shown:translate-x-3
                        peer-placeholder-shown:text-lg
                        peer-placeholder-shown:text-gray-500
                        text-xs
                        text-gray-500
                        duration-300
                        transform
                        -translate-y-2
                        top-4
                        z-5
                        left-3.5
                        bg-transparent
                        rounded-full
                `}>{label}</label>
        </div>
    );
});

export default FloatingInputComponent;
