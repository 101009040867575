import React, {FC, ReactElement} from 'react';

interface IProps {
    children: ReactElement,
    name?: string,
    id?: string,
    label?: string,
    onchange: any,
    value?: any,
    isMultiple?: boolean
}

const SelectFieldComponent: FC<IProps> = ({children, name, id, label, onchange, value, isMultiple}) => {
    return (
        <div className="relative w-full text-start">
            <label className={'w-full block text-white bg-gray-400 px-3 pb-3 pt-1 rounded-t-xl -mb-2 bottom-5'} htmlFor={id}>{label}</label>
            <select className={`relative block bg-white rounded-xl w-full text-gray-500 text-sm px-4 pt-1 ${isMultiple ? 'h-20' : 'h-16'} focus:outline-none focus:border focus:border-gray-600`}
                    id={id}
                    onChange={({target}) => onchange(target)}
                    name={name}
                    multiple={isMultiple}
                    value={value}>
                <option value="">Selecione</option>
                {children}
            </select>
        </div>
    );
};

export default SelectFieldComponent;
