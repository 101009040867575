import {SyntheticEvent, useCallback, useRef, useState} from 'react';
import axiosClient from "../../services/axios";
import {useStateContext} from "../../contexts/StateContextProvider";
import router from "../../router";
import FloatingInputComponent from "../../components/Forms/FloatingInput";
import Button from "../../components/Forms/Button";
import {HiOutlineX} from "react-icons/hi";
import {toast} from "react-toastify";

interface erro {
    message: string
}

const Login = () => {
    const {setCurrentUser, setUserToken, testToken} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        if (user && password) {
            axiosClient.post('/auth/login', {user, password})
                .then(({data}) => {
                    setUserToken(data?.token);
                    setCurrentUser(data?.user)
                    setTimeout(function () {
                        testToken();
                    }, 500)
                    router?.navigate('/')
                })
                .catch((data) => {})
                .finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            });

        } else {
            toast.error("Preencha os campos abaixo!")
            setLoading(false);
        }

    }
    return (
        <form action={'#'} method={'POST'} onSubmit={onSubmit}>
            <main className={'flex flex-col gap-4 justify-center items-center w-full'}>
                <FloatingInputComponent type={'text'} label={'Usuário'} name={'user'} id={'user'} onchange={setUser}/>
                <FloatingInputComponent type={'password'} label={'Senha'} name={'password'} id={'password'} onchange={setPassword}/>
                <Button loading={loading}>
                     AVANÇAR
                </Button>
            </main>
        </form>
    )
        ;
};

export default Login;
